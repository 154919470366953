/* eslint-disable @typescript-eslint/no-explicit-any */

import { observable } from 'mobx'
import { list, object, primitive, serializable } from 'serializr'
import {
  BackIssuesSection,
  Chapter,
  GetInTouch,
  PartialChapter,
} from 'stores/contentStore/contentTypes'

export interface IHub {
  dummyThumbnail: string
  articlesLoaded: number
  featuredArticlesTopBannerIds: string[]
  featuredArticle1: PartialChapter | null
  featuredArticle2: PartialChapter | null
  aboutLiveData: GetInTouch | null
  subscribeData: GetInTouch | null
  pensionersData: GetInTouch | null
  backIssues: BackIssuesSection[] | null
}

export class HubRoom implements IExtendedHubRoom {
  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  description: string = ''

  @serializable
  @observable
  url: string = ''

  @serializable
  @observable
  image: string = ''

  @serializable
  @observable
  imageHover: string = ''
}

export class Hub implements IHub {
  @serializable
  @observable
  dummyThumbnail: string = ''

  @serializable
  @observable
  articlesLoaded: number = 0

  @serializable(list(primitive()))
  @observable
  featuredArticlesTopBannerIds: string[] = []

  @serializable(object(Chapter))
  @observable
  featuredArticle1: PartialChapter | null = null

  @serializable(object(Chapter))
  @observable
  featuredArticle2: PartialChapter | null = null

  @serializable(object(GetInTouch))
  @observable
  aboutLiveData: GetInTouch | null = null

  @serializable(object(GetInTouch))
  @observable
  subscribeData: GetInTouch | null = null

  @serializable(object(GetInTouch))
  @observable
  pensionersData: GetInTouch | null = null

  @serializable(list(object(BackIssuesSection)))
  @observable
  backIssues: BackIssuesSection[] | null = null
}

// >>>> DANIOTH
export interface IExtendedHub extends IHub {
  rooms: HubRoom[]
}

export interface IExtendedHubRoom {
  title: string
  description: string
  url: string
  image: string
  imageHover: string
}

export class HubWithRooms extends Hub implements IExtendedHub {
  @serializable(list(object(HubRoom)))
  @observable
  rooms: HubRoom[] = []
}

// <<<< DANIOTH
