import bind from 'bind-decorator'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { getErrorMessage } from 'utils/errors'
import {
  importFromComponents,
  importFromModules,
  importFromProject,
  isModuleLoadingError,
  promiseTryCatchHandler,
} from 'utils/importHelper'
import { AnyObject } from 'utils/types'

type IModuleLoaderProps = AnyObject & {
  moduleName: string
  /**
   * Defines if default implementation should be loaded
   * when project implementation is missing
   */
  loadDefaultVersion?: boolean
  /** Differentiate between modules and components(location) */
  isModule: boolean
  moduleLoadedCallback?: () => void
}

type ComponentType =
  | React.ComponentClass<AnyObject, AnyObject>
  | React.FunctionComponent<AnyObject>

@observer
export class ModulesAndComponentsLoader extends React.Component<IModuleLoaderProps> {
  @observable.ref
  component: ComponentType | null = null

  async componentDidMount() {
    await this.loadModule()
  }

  async componentDidUpdate(prevProps: IModuleLoaderProps) {
    if (prevProps.moduleName !== this.props.moduleName) {
      await this.loadModule()
    }
  }

  async loadModule() {
    if (!this.props.moduleName) {
      return
    }
    const { loadDefaultVersion: loadFallbackVersion = true } = this.props
    const moduleLoadedFromProject:
      | boolean
      | undefined = await this.importModuleFromProject()

    if (!moduleLoadedFromProject && loadFallbackVersion) {
      await this.importDefaultModule()
    }
  }

  async importModuleFromProject(): Promise<boolean | never> {
    const { moduleName, isModule } = this.props
    const shouldThrowModuleLoadingError = true
    const folder = isModule ? 'modules' : 'components'
    const projectModulePromise = importFromProject<ComponentType>(
      `${folder}/${moduleName}`,
      shouldThrowModuleLoadingError
    )
    const [
      ProjectModule,
      projectImportError,
    ] = await promiseTryCatchHandler<ComponentType>(projectModulePromise)

    if (ProjectModule) {
      this.setComponentAndCallback(ProjectModule)
      return true
    }
    if (!isModuleLoadingError(projectImportError)) {
      throw projectImportError
    }
    return false
  }

  async importDefaultModule() {
    const { moduleName, isModule } = this.props
    /**
     * Dynamic imports with just variable are impossible
     * with webpack
     * https://stackoverflow.com/questions/58349959/react-dynamic-import-using-a-variable-doesnt-work
     * That's why we have to define 2 different functions
     * for importing from different folders
     */

    const defaultImport = isModule ? importFromModules : importFromComponents

    const defaultModulePromise = defaultImport<ComponentType>(moduleName)
    const [
      DefaultModule,
      defaultImportError,
    ] = await promiseTryCatchHandler<ComponentType>(defaultModulePromise)
    if (DefaultModule) {
      this.setComponentAndCallback(DefaultModule)
      return
    }

    if (!isModuleLoadingError(defaultImportError)) {
      throw defaultImportError
    }
    console.error(getErrorMessage(defaultImportError))
    this.props.moduleLoadedCallback?.()
  }

  @bind
  @action
  setComponentAndCallback(Component: ComponentType) {
    this.component = Component
    this.props.moduleLoadedCallback?.()
  }

  render() {
    const Component = this.component
    const { moduleName: _moduleName, isModule: _isModule, ...rest } = this.props
    if (!Component) {
      return null
    }
    return <Component {...rest} />
  }
}
