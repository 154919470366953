import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'

export const OverlayStyles = (_theme: ThemeType) =>
  createStyles({
    container: {
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 2600,
    },
    'inner-container': {
      width: '100%',
      height: '100%',
    },
  })
