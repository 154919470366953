import { WithStyles, WithTheme } from '@material-ui/styles'
import { appConfig } from 'config'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { Debounce } from 'lodash-decorators/debounce'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { ModulesAndComponentsLoader } from 'modules/ModulesLoader'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { contentStore } from 'stores/contentStore/contentStore'
import { Subchapter } from 'stores/contentStore/contentTypes'
import { currentStory } from 'stores/currentStoryStore'
import { MatchParams, navigationStore } from 'stores/navigationStore'
import { ThemeType } from 'theme'
import { isNotNullOrUndefined } from 'utils/validation'
import {
  ComponentConstructor,
  IScrollPageChildProps,
  ScrollPageChild,
} from '../common/ScrollPage'
import { SectionComponentStyles } from './section.style'

export interface SectionProps
  extends WithStyles<typeof SectionComponentStyles>,
    WithTheme<ThemeType>,
    RouteComponentProps<MatchParams> {
  data: Subchapter
  active: boolean
}

@observer
class SectionWrapperCls extends ScrollPageChild<SectionProps> {
  @observable.ref
  backgroundRef = React.createRef<HTMLDivElement>()

  get parentChapter() {
    return contentStore.getParentChapterBySubchapterId(this.props.data.id)
  }

  get isFirstInChapter() {
    return this.parentChapter?.subchaptersIds[0] === this.props.data.id
  }

  get isLastInChapter() {
    if (this.parentChapter) {
      const nOfChapters = this.parentChapter.subchaptersIds.length
      return (
        this.parentChapter.subchaptersIds[nOfChapters - 1] ===
        this.props.data.id
      )
    }
    return false
  }

  render() {
    return (
      <ModulesAndComponentsLoader
        moduleName="Section/Section"
        isModule={false}
        data={this.props.data}
      />
    )
  }

  componentDidUpdate(prevProps: IScrollPageChildProps & SectionProps) {
    if (
      prevProps.active !== this.props.active ||
      prevProps.activeSection !== this.props.activeSection
    ) {
      this.updateUrl()
    }
    if (this.props.active) {
      this.handleSidebarVisibility()
    }
  }

  @Debounce(navigationStore.scrollUpdateTimeout)
  updateUrl() {
    const subcontent = contentStore.getSubcontentIfExists(
      this.props.data.subcontentId
    )
    if (
      subcontent?.shouldRender &&
      this.props.activeSection &&
      this.props.active &&
      this.props.match
    ) {
      navigationStore.updateSectionUrl(
        this.props.match.params,
        this.props.data.uid
      )
    }
  }

  handleSidebarVisibility() {
    if (appConfig.noNextChapterPreview && this.isLastInChapter) {
      const subcontent = contentStore.getSubcontentIfExists(
        this.props.data.subcontentId
      )
      const background = subcontent?.createBackgroundComponent(
        this.props.data.id,
        this.backgroundRef,
        undefined,
        this.props.scrollPos
      )
      // // If no background rendered, height of the section must be 0
      const rendersBackground = !!background
      const lastSectionFinished =
        isNotNullOrUndefined(this.props.scrollPos) &&
        ((rendersBackground && this.props.scrollPos > 1.1) ||
          (!rendersBackground && this.props.scrollPos > 0.2))
      if (lastSectionFinished) {
        currentStory.setSidebarShown(false)
        return
      }
    }

    // For projects that have no cover to show the sidebar, show it from section
    if (appConfig.noCover && this.isFirstInChapter) {
      const firstSectionStarted =
        isNotNullOrUndefined(this.props.scrollPos) && this.props.scrollPos >= 0
      if (firstSectionStarted) {
        currentStory.setSidebarShown(true)
      }
    }
  }
}

// !TODO ts hack
export default withRouter(
  SectionWrapperCls
) as ComponentConstructor<SectionProps>
