import { bind } from 'bind-decorator'
import { action, computed, observable } from 'mobx'
import { navigationStore } from './navigationStore'
import { decodingRouterHelper } from './routerStore'

export class TopBarStore {
  @observable
  expanded: boolean = false

  // This props to control if topbar is ready to render after fetching tabs content
  @observable
  topBarShouldRender: boolean = false

  @observable
  topBarVisible: boolean = true

  tabsQueryParams: string[] = ['metaTab', 'tabsOnMobile']

  /*
   * Used on mobile, as there tabs are opened from Meta button, not by click on the tab
   **/
  defaultTab: string = ''

  @bind
  @action
  setVisibility(value: boolean) {
    this.topBarVisible = value
  }

  @bind
  @action
  toggleExpansion(value?: boolean) {
    if (value !== undefined) {
      this.expanded = value
    } else {
      this.expanded = !this.expanded
    }
  }

  @bind
  @action
  setTopBarRender(value: boolean) {
    this.topBarShouldRender = value
  }

  @action
  setActiveTab(newValue: string) {
    const params = decodingRouterHelper.qsParsed
    let push = true
    if (params.metaTab) {
      push = false
    }
    params.metaTab = newValue
    this.addTabsQueryParam('metaTab')
    navigationStore.replaceParams(params, push)
  }

  hideTabs() {
    const params = {
      ...decodingRouterHelper.qsParsed,
      ...navigationStore.clearQueryParams(this.tabsQueryParams),
    }
    navigationStore.replaceParams(params, true)
  }

  @computed
  get activeTab(): string | undefined {
    const parsed = decodingRouterHelper.qsParsed
    if (parsed.metaTab === null) {
      return undefined
    }
    return parsed.metaTab as string
  }

  @bind
  addTabsQueryParam(newParam: string) {
    if (this.tabsQueryParams.indexOf(newParam) < 0) {
      this.tabsQueryParams.push(newParam)
    }
  }
}

export const topBarStore = new TopBarStore()
