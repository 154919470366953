import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'

export const AppStyles = (theme: ThemeType) =>
  createStyles({
    '@global': {
      html: {
        fontSize: '100%',
        overflow: 'hidden',
        position: 'relative',
        height: '100%',
        width: '100%',
        '@media (min-width: 768px)': {
          fontSize: '112.5%',
        },
      },
      button: {
        '&:focus': {
          outline: 0,
        },
      },
      '.gwd-img-tu41': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        transformOrigin: '665.333px 457.033px 0px',
        '-webkit-transform-origin': '665.333px 457.033px 0px',
        '-moz-transform-origin': '665.333px 457.033px 0px',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
      },
      '.gwd-div-qdh2': {
        position: 'absolute',
        width: 210.667,
        height: 70.6667,
        left: 452.667,
        top: 364.333,
        background: 'linear-gradient(to right, #fff 0, #000 100%)',
        backgroundColor: 'transparent',
      },
      '.gwd-path-bwac': {
        position: 'absolute',
        stroke: '#000',
        strokeWidth: 1,
        height: 167.278,
        width: 214.804,
        left: 1134.33,
        top: 570.484,
        fill: '#3b4f25',
      },
      body: {
        'overscroll-behavior': 'none',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.white(),
        '-webkit-transform': 'translateZ(0)',
        '-webkit-backface-visibility': 'hidden',
        fontFamily: `${theme.fonts.primary.serif}, ${theme.fonts.primary.sansSerif}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
        '-webkitFontSmoothing': 'antialiased',
        '-mozOsxFontSmoothing': 'grayscale',
      },
      'a, a:active, a:visited, a:hover': {
        textDecoration: 'none',
        color: theme.palette.white(),
      },
      h1: {
        fontSize: '2.8rem',
      },
      '.number-sub': {
        fontSize: '75%',
        lineHeight: 0,
        position: 'relative',
        verticalAlign: 'baseline',
        bottom: '-0.25rem',
      },
      '.number-sup': {
        fontSize: '75%',
        lineHeight: 0,
        position: 'relative',
        verticalAlign: 'baseline',
        top: '-0.5rem',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.react-swipeable-view-container': {
        height: '100%',
      },
      '*': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      },
      '*:not(input)': {
        '-webkit-touch-callout': 'none' /* iOS Safari */,
        '-webkit-user-select': 'none' /* Safari */,
        '-khtml-user-select': 'none' /* Konqueror HTML */,
        '-moz-user-select': 'none' /* Firefox */,
        '-ms-user-select': 'none' /* Internet Explorer/Edge */,
        'user-select': 'none',
      },
    },
  })
