import { observable } from 'mobx'
import { list, object, serializable } from 'serializr'

export interface INavigationItem {
  name: string
  slug: string
  order: number
}

export class NavigationItem implements INavigationItem {
  @serializable
  @observable
  name: string = ''

  @serializable
  @observable
  slug: string = ''

  @serializable
  @observable
  order: number = -1
}

export interface IChapterItem {
  name: string
  url: string
}

export class ChapterItem implements IChapterItem {
  @serializable
  @observable
  name: string = ''

  @serializable
  @observable
  url: string = ''
}

export class Navigation {
  @serializable(list(object(NavigationItem)))
  @observable
  navigationItems: NavigationItem[] = []

  @serializable(list(object(ChapterItem)))
  @observable
  chapterItems: ChapterItem[] = []
}
