import { DateTime } from 'luxon'
import { runInAction } from 'mobx'
import {
  Clazz,
  ClazzOrModelSchema,
  custom,
  deserialize,
  getDefaultModelSchema,
  serialize,
} from 'serializr'
import { importFromProject } from 'utils/importHelper'
import { AnyObject } from 'utils/types'
import { SectionItem } from './sectionItems/SectionItem'
import { ISectionItemRaw } from './sectionItems/sectionItemInterfaces'

export function listFactory<ELEMENT, DATA, PARAMS>(
  factoryFct: (d: DATA, p?: PARAMS) => ELEMENT
) {
  return custom(
    (listtoSerialize) => {
      return listtoSerialize.map((clazz: AnyObject) => serialize(clazz))
    },
    (toDoDeserlize) =>
      toDoDeserlize.map((obj: DATA) => {
        return factoryFct(obj, undefined)
      })
  )
}

export function leaveUnchagedListFactory<ELEMENT, DATA, PARAMS>(
  _factoryFct: (d: DATA, p: PARAMS) => ELEMENT
) {
  return custom(
    (listtoSerialize) => {
      return listtoSerialize.map((clazz: AnyObject) => serialize(clazz))
    },
    (toDoDeserlize, _context) =>
      toDoDeserlize.map((obj: AnyObject) => {
        return obj
      })
  )
}

export function contextFactory<T, E extends Clazz<T>>(Claz: E) {
  const schema = getDefaultModelSchema(Claz)
  if (schema) {
    schema.factory = (context) => new Claz(context)
  }

  return Claz
}

export function mapListFactoryAsync<
  ELEMENT,
  DATA,
  KEYNAME extends keyof DATA,
  PARAMS
>(idName: KEYNAME, factoryFct: (d: DATA, p: PARAMS) => Promise<ELEMENT>) {
  return custom(
    (objectToSerialize) => {
      const arrayFromInputObject = Object.values(objectToSerialize)
      return arrayFromInputObject.map((clazz: unknown) => serialize(clazz))
    },
    /* eslint-disable*/
    async (
      arrayToDeserlize: DATA[],
      context: any,
      _oldValue: unknown,
      cb: (err: any, result?: any) => void
    ) => {
      const map: Map<string, ELEMENT> = new Map()
      try {
        for (const obj of arrayToDeserlize) {
          const newObject = await factoryFct(obj, context.args)
          runInAction(() => {
            const id = (obj[idName] as unknown) as string
            map.set(id, newObject)
          })
        }
        runInAction(() => {
          cb(null, map)
        })
      } catch (err) {
        console.error(err)
        cb(err)
      }
    }
    /* eslint-enable*/
  )
}

export function isoDate() {
  return custom(
    (date?: DateTime) => {
      return date?.toISO()
    },
    (isoDate?: string) => {
      return isoDate && DateTime.fromISO(isoDate)
    }
  )
}

export function mapAsArrayWithMerge<
  T extends { id: string },
  K extends keyof T
>(schema: ClazzOrModelSchema<T>, keyPropertyName: K) {
  return custom(
    (objectToSerialize) => {
      const arrayFromInputObject = Object.values(objectToSerialize)
      return arrayFromInputObject.map((clazz: unknown) => serialize(clazz))
    },
    (
      arrayToDeserlize: T[],
      _context: unknown,
      oldValue: {
        _data: Map<string, { value: T }>
      },
      cb: (err: unknown, result?: Map<string, T>) => void
    ) => {
      const map: Map<string, T> = new Map()
      oldValue._data.forEach((el) => {
        map.set(el.value.id, el.value)
      })
      try {
        for (const obj of arrayToDeserlize) {
          const newObject = deserialize(schema, obj)
          const id = (obj[keyPropertyName] as unknown) as string
          map.set(id, newObject)
        }
        runInAction(() => {
          cb(null, map)
        })
      } catch (err) {
        console.error(err)
        cb(err)
      }
    }
  )
}

export async function createSectionItem(
  obj: ISectionItemRaw,
  parms = {}
): Promise<SectionItem | null> {
  let clazz: (new () => SectionItem) | null = null
  // !TODO fix any
  // Cannot fight the types from serializr
  // eslint-disable-next-line
  let subcontentType: any
  let res: SectionItem | null = null
  const shouldThrowModuleLoadingError = true
  try {
    const resource = await importFromProject(
      `sectionItems/${obj.type}/index.tsx`,
      shouldThrowModuleLoadingError
    )
    subcontentType = resource
  } catch (err) {
    try {
      const resource = (
        await import(`stores/contentStore/sectionItems/${obj.type}`)
      ).default
      subcontentType = resource
    } catch (err) {
      console.error(err)
      subcontentType = null
    }
  }
  if (subcontentType) {
    runInAction(() => {
      clazz = subcontentType
      res = deserialize(
        clazz!, // eslint-disable-line
        subcontentType.parseData(obj.data),
        undefined,
        parms
      )
    })
  }
  return res
}
