import { WithStyles, withStyles } from '@material-ui/styles'
import { computed } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { breakpoints } from 'stores/breakpointsStore'
import { contentStore } from 'stores/contentStore/contentStore'
import { decodingRouterHelper } from 'stores/routerStore'
import { OverlayStyles } from './overlay.style'

type IMenuProps = WithStyles<typeof OverlayStyles>

@observer
class SubcontentOverlayCls extends React.Component<IMenuProps> {
  @computed
  get shouldRender() {
    const parsed = decodingRouterHelper.qsParsed
    return !!(parsed.overlay && this.subcontentId)
  }

  @computed
  get subcontentId() {
    return (decodingRouterHelper.qsParsed.overlayId as string) ?? ''
  }

  render() {
    const { classes } = this.props
    const subcontent = contentStore.getSubcontentIfExists(this.subcontentId)
    if (!subcontent) {
      return null
    }
    const subchapter = contentStore.getParentSubchapterBySubcontentId(
      this.subcontentId
    )
    const subchapterId = subchapter?.id ?? ''

    return (
      this.shouldRender && (
        <div className={classes['container']}>
          <div className={classes['inner-container']}>
            {subcontent.createBackgroundComponent(
              subchapterId,
              undefined,
              !!breakpoints.phone,
              0
            )}
          </div>
        </div>
      )
    )
  }
}

export default withStyles(OverlayStyles, { name: 'Overlay' })(
  SubcontentOverlayCls
)
