import { MOBILE, DESKTOP } from 'utils/styles'
import { ThemeType } from 'theme'
import { createStyles } from '@material-ui/styles'

export const TopBarStyles = (theme: ThemeType) =>
  createStyles({
    container: {
      width: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      height: theme.constants.sizes.desktop.topBarHeight,
      display: 'flex',
      alignItems: 'center',
      zIndex: 20,
      opacity: 1,
      transform: 'translate(0, 0)',
      transition:
        'transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s, opacity 0s',
      willChange: 'transform',
      [`@media ${MOBILE}`]: {
        height: theme.constants.sizes.phone.topBarHeight,
      },
    },
    invisible: {
      opacity: 0,
      transform: 'translate(0, -200%)',
      transition:
        'transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0s 1s',
    },
    'button-placeholder': {
      width: 0,
    },
    'chapter-number': {
      width: theme.constants.sizes.desktop.topBarHeight,
      height: theme.constants.sizes.desktop.topBarHeight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.sideElements.desktop.accentColor(),
      textShadow: `0 3px 6px ${theme.palette.black(0.16)}`,
      fontFamily: theme.fonts.secondary.serif,
      fontSize: '2rem',
      lineHeight: 1.12,
      letterSpacing: '-0.66px',
      textAlign: 'center',
      color: theme.palette.sideElements.desktop.accentFontColor(),
      zIndex: 5,
      cursor: 'pointer',
      flexShrink: 0,
      [`@media ${MOBILE}`]: {
        display: 'none',
      },
    },
    'top-dark-overlay': {
      position: 'absolute',
      width: '100%',
      left: 0,
      zIndex: 1,
      [`@media ${DESKTOP}`]: {
        height: '200px',
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.black(
          0.7
        )}, ${theme.palette.black(0.6)} 43%, ${theme.palette.black(0)})`,
      },
      [`@media ${MOBILE}`]: {
        display: 'none',
      },
    },
    'narrow-overlay': {
      width: `calc(100% - ${theme.constants.sizes.desktop.sideBarWidth}px)`,
      left: theme.constants.sizes.desktop.sideBarWidth,
      height: 120,
      [`@media ${MOBILE}`]: {
        left: 0,
        width: '100%',
      },
    },
    'tab-icon': {
      [`@media ${DESKTOP}`]: {
        padding: '0 10px 0 0',
      },
    },
    icons: {
      display: 'flex',
      justifyContent: 'flex-end',
      zIndex: 1,
    },
  })
