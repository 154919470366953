import { RouterStore } from 'mobx-react-router'
import { computed } from 'mobx'
import qs from 'query-string'

export const routerStore = new RouterStore()
const ampRegex = /&amp;/g

class DecodingRouterHelper {
  @computed
  get locationSearch() {
    return routerStore.location?.search?.replace(ampRegex, '&') ?? ''
  }

  @computed
  get qsParsed() {
    return qs.parse(this.locationSearch)
  }
}

export const decodingRouterHelper = new DecodingRouterHelper()

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.routerStore = routerStore
