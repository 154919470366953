import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'

export const ScrollPageStyles = (_theme: ThemeType) =>
  createStyles({
    page: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: '100vw',
      position: 'relative',
      overflow: 'hidden',
      'overscroll-behavior': 'none',
    },
    'page-scrollbar-hider': {
      height: '100%',
      width: '100%',
      'overscroll-behavior': 'none',
      flex: '0 0 100%',
      overflowX: 'hidden',
      position: 'relative',
      '-webkit-transform': 'translateZ(0)',
      '-webkit-backface-visibility': 'hidden',
    },
    'page-scroller': {
      position: 'absolute',
      overflowY: 'auto',
      overflowX: 'hidden',
      left: '0px',
      'overscroll-behavior': 'none',
      right: '0px',
      top: '0px',
      bottom: '0px',
      height: '100%',
      '-webkit-overflow-scrolling': 'touch',
      scrollbarWidth: 'none' /* Firefox */,
      '-ms-overflow-style': 'none' /* IE 10+ */,
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
    'page-content': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      minHeight: '100%',
      'overscroll-behavior': 'none',
    },
    noscroll: {
      overflowY: 'hidden',
    },
    hidden: {
      height: 0,
      minHeight: '0 !important',
      opacity: 0,
    },
  })
