import { bind } from 'bind-decorator'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { breakpoints } from 'stores/breakpointsStore'
import classnames from 'classnames'

interface ISmartSwipeOverlay {
  handleClick: (() => void) | undefined
  className?: string
  style?: React.CSSProperties
  onTouchStart?(e: React.TouchEvent): void
  onTouchEnd?(e: React.TouchEvent): void
}

@observer
export class SmartSwipeOverlay extends React.Component<ISmartSwipeOverlay> {
  @observable mouseXPosition: number = 0

  @bind
  @action
  handleMouseDown(e: React.MouseEvent) {
    this.mouseXPosition = e.screenX
  }

  @bind
  @action
  handleClick(e: React.MouseEvent) {
    const delta = Math.abs(e.screenX - this.mouseXPosition)
    if (delta > 10 && breakpoints.desktop) {
      return
    }
    this.mouseXPosition = 0
    if (this.props.handleClick) {
      this.props.handleClick()
    }
  }

  render() {
    return (
      <div
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        style={this.props.style}
        onTouchStart={this.props.onTouchStart}
        onTouchEnd={this.props.onTouchEnd}
        className={classnames(this.props.className)}
      >
        {this.props.children}
      </div>
    )
  }
}
