import { computed, observable, action } from 'mobx'
import bind from 'bind-decorator'
import { getLocalStorageItem } from 'utils/localStorage'
import { convertTimeStringToSeconds } from 'utils/string'
import { navigationStore } from './navigationStore'
import { decodingRouterHelper } from './routerStore'

class VideoStore {
  @observable showPlay = true
  @observable currentVideoRemainingTime: number = 0
  @observable videoDuration = 0
  @observable videoPaused: boolean = true
  @observable videoStopped: boolean = false

  @bind
  @action
  setPause(value?: boolean) {
    if (value) {
      this.videoPaused = value
    } else {
      this.videoPaused = !this.videoPaused
    }
  }

  @bind
  @action
  setStop(value?: boolean) {
    if (value !== undefined) {
      this.videoStopped = value
    } else {
      this.videoStopped = !this.videoStopped
    }
  }

  @computed
  get showBanner() {
    const alreadyVisited = getLocalStorageItem('bannerDismissed')
    return (
      !alreadyVisited &&
      this.videoDuration > 15 &&
      2 * this.currentVideoRemainingTime < this.videoDuration &&
      this.currentVideoRemainingTime > this.videoDuration / 2 - 10
    )
  }

  @bind
  @action
  setRemainingTime(time: number, videoDuration?: string) {
    this.currentVideoRemainingTime = time
    this.videoDuration = convertTimeStringToSeconds(videoDuration) ?? 0
  }

  @bind
  @action
  setShowPlay(value: boolean) {
    this.showPlay = value
  }

  videoGalleryItemIsPlaying(id: string) {
    const parsed = decodingRouterHelper.qsParsed
    return parsed.videoid === id
  }

  playVideoGalleryItem(id: string) {
    const params = decodingRouterHelper.qsParsed
    params.videoid = id
    navigationStore.replaceParams(params, false)
  }

  stopVideoGalleryItem() {
    const params = {
      ...decodingRouterHelper.qsParsed,
      ...navigationStore.clearQueryParams(['videoid']),
    }
    navigationStore.replaceParams(params, false)
  }
}

export const videoStore = new VideoStore()
