import { createStyles, withStyles, WithStyles } from '@material-ui/styles'
import { bind } from 'bind-decorator'
import NextChapterCover from 'components/NextChapterCover/Loader'
import { appConfig } from 'config'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { contentStore } from 'stores/contentStore/contentStore'
import { Chapter } from 'stores/contentStore/contentTypes'
import { menuStore } from 'stores/menuStore'
import { sliderStore } from 'stores/sliderStore'
import { topBarStore } from 'stores/topBarStore'
import { ThemeType } from 'theme'
import { getErrorMessage } from 'utils/errors'
import {
  importFromComponents,
  importFromProject,
  isModuleLoadingError,
  isNullError,
  promiseTryCatchHandler,
} from 'utils/importHelper'
import ScrollPage, {
  PartialSlideProps,
  ScrollChildren,
} from '../common/ScrollPage'
import ScrollSidebar from '../ScrollSidebar/ScrollSideBar'
import { ContentFactory } from '../factories/contentFactory'
import { SideBarProps } from '../ScrollSidebar'

export interface INextChapterCover {
  data: Chapter
  nextData: Chapter
  active: boolean
  id: string
}

export interface ICover {
  data: Chapter
  active: boolean
  id: string
}

export interface IFooter {
  id: string
  active: boolean
  data: Chapter
}

export const SlideStyles = (_theme: ThemeType) => createStyles({})
interface IProps extends WithStyles<typeof SlideStyles> {
  data?: Chapter
  slideIdx: number
}

interface ExtNavigator extends Navigator {
  app: {
    exitApp: () => void
  }
}

@observer
class SlideCls extends React.Component<IProps> {
  @observable
  cover: React.ComponentClass | null | undefined = undefined

  @observable
  footer: React.ComponentClass | null | undefined = undefined

  loadingDisposer: IReactionDisposer

  constructor(a: IProps) {
    super(a)
    this.loadingDisposer = reaction(
      () => {
        return !!(
          this.props.data &&
          (this.cover ?? this.cover === null) &&
          (this.footer ?? this.footer === null) &&
          this.props.data.subchaptersFetched
        )
      },
      (timeToRender: boolean) => {
        if (timeToRender && !sliderStore.showContent) {
          window.setTimeout(() => sliderStore.setShowContent(true), 500)
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  async componentDidMount() {
    document.addEventListener('backbutton', this.onBackDown, false)
    await this.loadCover()
    await this.loadFooter()
    await this.fetchSubchaptersDataIfNeeded()
  }

  async componentDidUpdate(prevProps: IProps) {
    if (prevProps.data?.uid !== this.props.data?.uid) {
      await this.fetchSubchaptersDataIfNeeded()
    }
  }

  @bind
  async fetchSubchaptersDataIfNeeded() {
    if (
      this.props.data &&
      !this.props.data.subchaptersFetched &&
      this.isSlideToRender
    ) {
      await contentStore.fetchSubchapters(this.props.data.subchaptersIds)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('backbutton', this.onBackDown, false)
    this.loadingDisposer()
  }

  //works similar like <ModulesAndComponentsLoader/>
  async loadCover() {
    //1. Try to import cover from specific project (ex. path: src/projects/carllutz/componenets/Cover)
    const projectCoverPromise = importFromProject<React.ComponentClass>(
      'components/Cover'
    )
    const [
      projectCoverClass,
      projectError,
    ] = await promiseTryCatchHandler<React.ComponentClass>(projectCoverPromise)
    /**
     * If a chapter without cover is a desired outpout
     * specify so in config file, otherwise default
     * cover will be loaded
     */
    if (projectCoverClass ?? appConfig.noCover) {
      this.setCover(projectCoverClass)
      return
    }
    if (!isModuleLoadingError(projectError) && !isNullError(projectError)) {
      console.error(projectError)
    }

    const defaultCoverPromise = importFromComponents<React.ComponentClass>(
      'Cover'
    )
    const [
      defaultCoverClass,
      defaultCoverError,
    ] = await promiseTryCatchHandler<React.ComponentClass>(defaultCoverPromise)
    if (defaultCoverClass) {
      this.setCover(defaultCoverClass)
      return
    }
    if (defaultCoverError) {
      console.error(getErrorMessage(defaultCoverError))
    }
  }

  @bind
  @action
  setCover(module: React.ComponentClass | null) {
    this.cover = module
  }

  async loadFooter() {
    const componentClass: React.ComponentClass | null = await importFromProject(
      'components/Footer'
    )
    this.setFooter(componentClass)
  }

  @bind
  @action
  setFooter(module: React.ComponentClass | null) {
    this.footer = module
  }

  @bind
  onBackDown(e: Event) {
    e.preventDefault()
    e.stopPropagation()
    if (this.activeSlide) {
      if (menuStore.open) {
        menuStore.closeMenu()
      } else if (sliderStore.currentSlideIdx > 0) {
        sliderStore.currentSlideIdx = sliderStore.currentSlideIdx - 1
      } else (navigator as ExtNavigator).app.exitApp()
    }
  }

  @computed
  get activeSlide() {
    return this.props.slideIdx === sliderStore.currentSlideIdx
  }

  @computed
  get isSlideToRender() {
    return Math.abs(this.props.slideIdx - sliderStore.currentSlideIdx) <= 1
  }

  render() {
    const items: Array<ScrollChildren<PartialSlideProps> | undefined> = []

    const sectionsIds: string[] = []
    const chapter = this.props.data

    if (chapter) {
      let id = `intro-${chapter.id}`
      if (this.cover) {
        items.push({
          component: this.cover,
          props: {
            id,
            data: chapter,
            active: this.activeSlide,
          },
        })
      }
      if (this.cover !== null) {
        sectionsIds.push(id)
      }

      for (const subchapterId of chapter.subchaptersIds) {
        const subchapter = contentStore.subchapters.get(subchapterId)
        if (subchapter) {
          id = subchapter.id
          items.push({
            component: ContentFactory.build(subchapter),
            props: {
              data: subchapter,
              active: this.activeSlide,
            },
          })
          sectionsIds.push(id)
        }
      }

      id = `outro-${chapter.id}`
      items.push({
        component: NextChapterCover,
        props: {
          id,
          data: chapter,
          active: this.activeSlide,
          nextData:
            chapter && this.props.slideIdx < contentStore.chapters.length - 1
              ? contentStore.chapters[this.props.slideIdx + 1]
              : null,
        },
      })
      sectionsIds.push(id)

      id = `footer-${chapter.id}`
      if (this.footer) {
        items.push({
          component: this.footer,
          props: {
            id,
            data: chapter,
            active: this.activeSlide,
          },
        })
      }
      if (this.footer !== null) {
        sectionsIds.push(id)
      }
    }

    const sidenav: ScrollChildren<SideBarProps> = {
      component: ScrollSidebar,
      props: { chapter: chapter },
    }
    const shouldRender =
      sliderStore.showContent &&
      topBarStore.topBarShouldRender &&
      chapter?.timeToRender
    return shouldRender ? (
      <ScrollPage
        sidenav={sidenav}
        active={this.activeSlide}
        slideIdx={this.props.slideIdx}
        sectionsIds={sectionsIds}
      >
        {items}
      </ScrollPage>
    ) : null
  }
}

export const Slide = withStyles(SlideStyles)(SlideCls)
