import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'
import { MOBILE, DESKTOP } from 'utils/styles'

export const CookiesBannerStyles = (theme: ThemeType) =>
  createStyles({
    container: {
      width: '100%',
      height: 'auto',
      border: `1px solid ${theme.palette.white()}`,
      padding: '0.5rem',
      color: theme.palette.black(),
      marginBottom: '1rem',
      zIndex: 3000,
      position: 'fixed',
      top: 0,
      opacity: 0,
      transition: 'opacity 0.8s',
    },
    'container-visible': {
      opacity: 1,
      transition: 'opacity 0.8s',
    },
    'inner-container': {
      backgroundColor: theme.palette.white(),
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '1rem 3rem',
      position: 'relative',
      [`@media ${MOBILE}`]: {
        flexDirection: 'column',
        padding: '1rem 1.5rem',
      },
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    'text-content': {
      width: '100%',
      fontSize: '0.75rem',
      lineHeight: 1.6,
      textAlign: 'center',
    },
    message: {
      display: 'inline',
      '& p': {
        display: 'inline',
      },
    },
    'buttons-container': {
      display: 'flex',
      [`@media ${DESKTOP}`]: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      },
      [`@media ${MOBILE}`]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
    'accept-button': {
      backgroundColor: theme.palette.black(0.4),
      color: theme.palette.white(),
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.33,
      letterSpacing: '1.68px',
      margin: '20px 0 0',
      '&:hover': {
        backgroundColor: theme.palette.black(0.2),
      },
    },
    'button-hover-container': {
      position: 'relative',
    },
    'more-info-text': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  })
