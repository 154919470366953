import { FilledContentRelationshipField } from '@prismicio/types'
import { list, object, primitive, serializable } from 'serializr'
interface ILinkToFullscreenSubcontent {
  text: string
  link: FilledContentRelationshipField<string | undefined> | undefined
}
interface ILink {
  text: string
  link: string
}

interface IImage {
  url: string
  altImage: string
}

export interface IFilmraum {
  title: string
  description: string
  image: IImage
  meta: string
  external: ILink
  film: ILinkToFullscreenSubcontent
}
class FilledContentRelationship implements FilledContentRelationshipField {
  @serializable
  // eslint-disable-next-line @typescript-eslint/naming-convention
  link_type: 'Document' = 'Document'

  @serializable
  id: string = ''

  @serializable
  type: string = ''

  @serializable(list(primitive()))
  tags: string[] = ['']

  @serializable
  lang: string = ''
}

class Link implements ILink {
  @serializable
  text: string = ''

  @serializable
  link: string = ''
}
class VideoSubcontentRelationship implements ILinkToFullscreenSubcontent {
  @serializable
  text: string = ''

  @serializable(object(FilledContentRelationship))
  link:
    | FilledContentRelationshipField<string | undefined>
    | undefined = undefined
}

class Image implements IImage {
  @serializable
  url: string = ''

  @serializable
  altImage: string = ''
}

export class Filmraum implements IFilmraum {
  @serializable
  title: string = ''

  @serializable
  description: string = ''

  @serializable(object(Image))
  image: Image = { url: '', altImage: '' }

  @serializable
  meta: string = ''

  @serializable(object(Link))
  external: Link = { text: '', link: '' }

  @serializable(object(VideoSubcontentRelationship))
  film: VideoSubcontentRelationship = { text: '', link: undefined }
}
