import { observable } from 'mobx'
import { list, object, serializable } from 'serializr'
import { IWithUrl } from 'stores/contentStore/cmsAdapters/prismic/prismicSubcontentDataProcessor'

/* eslint-disable */
export interface IPrismiscAussenraumSection {
  text: string
  title: string
  image: IWithUrl
  link: IWithUrl
  button_text: string
}
/* eslint-enable */

export interface IAussenraumSection {
  text: string
  title: string
  image: string
  link: string
}
export interface IAussenraum {
  title: string
  text: string
  image: string
  sections: IAussenraumSection[]
}

export class AussenraumBasic {
  @serializable
  title: string = ''

  @serializable
  text: string = ''

  @serializable
  image: string = ''
}

export class AusenraumSection extends AussenraumBasic {
  @serializable
  link: string = ''

  @serializable
  buttonText: string = ''
}
export class Aussenraum extends AussenraumBasic {
  @serializable(list(object(AusenraumSection)))
  @observable
  sections: AusenraumSection[] = []
}
