import {
  prismicAdapter as daniothPrismicAdapter,
  DaniothPrismicAdapter,
} from 'projects/danioth/stores/prismicAdapter'
import {
  NuclearGamesAdapter,
  prismicAdapter as nuclearGamesAdapter,
} from 'projects/nuclear_games/stores/prismicAdatpter'

import { AppName } from 'utils/enums'
import {
  prismicAdapter as defaultPrismicAdapter,
  PrismicAdapter,
} from './prismicAdapter'

type Adapter = NuclearGamesAdapter | DaniothPrismicAdapter | PrismicAdapter
export const getAdapter = (): Adapter => {
  switch (process.env.REACT_APP_PROJECT_NAME) {
    case AppName.NuclearGames:
      return nuclearGamesAdapter
    case AppName.Danioth:
      return daniothPrismicAdapter
    default:
      return defaultPrismicAdapter
  }
}
