import { observable, action, computed } from 'mobx'
import { breakpoints } from './breakpointsStore'
import { appConfig } from 'config'
import { bind } from 'bind-decorator'

class Window {
  @observable width = window.innerWidth
  @observable height = window.innerHeight
  @observable orientationJustChanged: boolean = false
  resizeTimeout: number = 0

  constructor() {
    window.addEventListener(
      'resize',
      () => {
        // Important to call this check before updating height and width
        if (this.orientationChangeDetected) {
          this.setOrientationChanged(true)
        } else {
          this.setOrientationChanged(false)
        }
        this.updateViewportHeight()
        this.updateViewportWidth()
        this.setOrientationChanged(false)
      },
      true
    )

    setInterval(() => {
      window.scrollTo(0, -1)
      this.updateViewportHeight()
      this.updateViewportWidth()
    }, 1000)
  }

  @action
  updateViewportHeight = () => {
    if (window.innerHeight !== this.height) {
      this.height = window.innerHeight
    }
  }

  @computed
  get textBoxWidth() {
    return breakpoints.desktop
      ? Math.min(appConfig.sectionTextBoxWidth, this.width * 0.45)
      : this.width
  }

  @computed
  get textBoxMargin() {
    return this.width - this.textBoxWidth
  }

  @action
  updateViewportWidth = () => {
    if (window.innerWidth !== this.width) {
      this.width = window.innerWidth
    }
  }

  @computed
  get isLandscape() {
    return this.height < this.width
  }

  get windowIsLandscape() {
    return window.innerHeight < window.innerWidth
  }

  @computed
  get orientationChangeDetected() {
    return (
      this.orientationChangedToPortrait || this.orientationChangedToLandscape
    )
  }

  @computed
  get orientationChangedToPortrait() {
    return this.isLandscape && !this.windowIsLandscape
  }

  @computed
  get orientationChangedToLandscape() {
    return !this.isLandscape && this.windowIsLandscape
  }

  @computed
  get imageAreaIsLandscape() {
    return this.width - this.textBoxWidth > this.height
  }

  @computed
  get isSmallTabletInPortrait() {
    return !this.isLandscape && this.width >= 768 && this.width < 1024
  }

  @bind
  @action
  setOrientationChanged(value: boolean) {
    this.orientationJustChanged = value
  }
}

export const windowStore = new Window()

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.windowStore = windowStore
