import bind from 'bind-decorator'
import { action, runInAction } from 'mobx'
import { update } from 'serializr'
import { ContentStore } from 'stores/contentStore/globalStore'
import { FullscreenSubcontent } from 'stores/contentStore/subcontent_modules'
import { prismicAdapter } from './prismicAdatpter'

export class NuclearGamesContentStore extends ContentStore {
  async fetchKnowledge() {
    const knowledgeBaseData = await prismicAdapter.fetchKnowledgeBase()
    runInAction(() => {
      update(
        ContentStore,
        this,
        { knowledgeBase: knowledgeBaseData },
        undefined
      )
    })
  }

  @bind
  @action
  async fetchAndSaveFullscreenSubcontent() {
    const subcontentData = await prismicAdapter.fetchFullscreenSubcontent()
    const result: FullscreenSubcontent[] = await this.saveFullscreenSubcontent(
      subcontentData
    )
    return result
  }
}

export const contentStore = new NuclearGamesContentStore()
