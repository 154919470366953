/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { giveColor } from 'utils/styles'

// Generate types from defaultTheme
export const defaultTheme = {
  palette: {
    white: (opacity?: number) => giveColor(255, 255, 255, opacity),
    black: (opacity?: number) => giveColor(0, 0, 0, opacity),
    secondary: {
      main: 'rgb(185, 31, 31)',
      dark: 'rgba(185, 31, 31, 0.7)',
      light: 'rgba(185, 31, 31, 0.3)',
      _500: '', //=light
      _600: '',
      _700: '', //=main
      _800: '',
      _900: '', //=dark
    },
    primary: {
      main: '#ffffff',
      dark: 'rgba(255, 255, 255, 0.7)',
      light: 'rgba(255, 255, 255, 0.3)',
      _500: '', //=light
      _600: '',
      _700: '', //=main
      _800: '',
      _900: '', //=dark
    },

    // OLD projects stuff
    sectionText: {
      desktop: {
        background: 'transparent',
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
      phone: {
        background: giveColor(10, 10, 10, 0.95),
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
    sectionBackground: {
      desktop: {
        background: `linear-gradient(to left, ${giveColor(
          0,
          0,
          0,
          0.9
        )}, ${giveColor(0, 0, 0, 0)})`,
        backgroundReversed: `linear-gradient(to right, ${giveColor(
          0,
          0,
          0,
          0.9
        )}, ${giveColor(0, 0, 0, 0)})`,
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
      phone: {
        background: giveColor(0, 0, 0, 0.95),
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
    cover: {
      desktop: {
        background: `linear-gradient(to bottom, ${giveColor(
          0,
          0,
          0,
          0.3
        )}, ${giveColor(0, 0, 0, 0.6)})`,
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
      phone: {
        background: `linear-gradient(to bottom, ${giveColor(
          0,
          0,
          0,
          0.3
        )}, ${giveColor(0, 0, 0, 0.6)})`,
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
    // Videogalerie, Chronik, etc.
    materials: {
      desktop: {
        background: giveColor(255, 255, 255, 1.0),
        fontColor: (opacity?: number) => giveColor(0, 0, 0, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
      phone: {
        background: giveColor(255, 255, 255, 1.0),
        fontColor: (opacity?: number) => giveColor(0, 0, 0, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
    sideElements: {
      desktop: {
        background: 'linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0.9))',
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
      phone: {
        background: 'linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0.9))',
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
    quizzes: {
      desktop: {
        background: 'transparent',
        fontColor: (opacity?: number) => giveColor(0, 0, 0, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
  },
  constants: {
    sizes: {
      desktop: {
        topBarHeight: 80,
        sideBarWidth: 80,
        bottomBarHeight: 80,
        standardButtonSize: 80,
      },
      tablet: {
        topBarHeight: 80,
        sideBarWidth: 80,
        bottomBarHeight: 80,
        standardButtonSize: 80,
      },
      phone: {
        topBarHeight: 50,
        sideBarWidth: 15,
        bottomBarHeight: 46,
        standardButtonSize: 46,
        menuButtonSize: 54,
      },
    },
  },
  fonts: {
    primary: {
      serif: '',
      sansSerif: '',
    },
    secondary: {
      serif: '',
    },
    bold: {
      serif: '',
      sansSerif: '',
    },
  },
  overrides: {},
  typography: {
    h1: {
      mainContent: {},
      subContent: {},
    },
    h2: {
      mainContent: {},
      subContent: {},
    },
    h3: {
      mainContent: {},
      subContent: {},
    },
  } as IFontSizeResponsive,
}

export type ThemeType = typeof defaultTheme

interface IFontStyleBase {
  fontFamily: string
  color: string
  fontWeight?: number
  letterSpacing?: string | number
}

export interface IFontStyle extends IFontStyleBase {
  fontSize: string
  lineHeight: number
}

export enum EScreenSizes {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  PHONE = 'phone',
}

export enum EFontTypes {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  body1 = 'body1',
  body2 = 'body2',
  category = 'category',
  quotes = 'quotes',
  button = 'button',
  caption = 'caption',
}

export enum EContentTypes {
  mainContent = 'mainContent',
  subContent = 'subContent',
}

export type IFontStyles = {
  [key in EFontTypes]: {
    [key in EContentTypes]: {
      [key in EScreenSizes]: IFontStyle
    }
  }
}

export type IFontSizeResponsive = {
  [key in EFontTypes]: {
    [key in EContentTypes]: CSSProperties
  }
}
