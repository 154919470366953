import { serializable } from 'serializr'

export class TooltipItem {
  @serializable
  id: string = ''

  @serializable
  title: string = ''

  @serializable
  description: string = ''
}
