import { IChapter } from 'stores/contentStore/contentTypes'
import { defaultTheme, ThemeType } from 'theme'
import escherTheme from 'projects/escher/theme'
import { importFromProject } from 'utils/importHelper'
import { ConfigType } from 'config-type'

export function getAppConfig<T = Record<string, unknown>>(): T & ConfigType {
  return appConfig as T & ConfigType
}

export const defaultConfig: ConfigType = {
  prismicPath: '',
  allowKnowledgeBase: true,
  showLegalInsideMenu: false,
  modules: [],
  languages: [
    {
      code: 'de-ch',
      name: 'Deutsch',
    },
  ],
  tabsAreClosable: false,
  showThumbnailInFullscreenOnMobile: false,
  useScrollbarForNavigation: true,
  backgroundsAreSticky: {
    desktop: true,
    phone: true,
  },
  stickyPositionExceptions: ['scrollytelling'],
  sectionTextBoxWidth: 600,
  topBarButtons: {
    desktop: [],
    phone: [],
  },
  landingPageRoute: '/',
  notFoundRoute: '/',
  stickyBackgroundHasFixedWidth: false,
  prismicModelsNamesMap: {
    page: 'chapter',
    section: 'subchapter',
    legal: 'imprint-sources-rights',
    impressumSlug: 'impressum',
    quellenSlug: 'text--und-bildquellenverzeichnis',
    dataPrivacySlug: 'datenschutz',
    subcontentNamesArray: [
      'fullscreen_subcontent_video',
      'fullscreen_subcontent_image',
      'fullscreen_subcontent_text',
      'fullscreen_subcontent_gallery',
      'fullscreen_subcontent_graphics',
      'fullscreen_subcontent_scrollytelling',
    ],
  },
  legalSections: ['Impressum', 'Datenschutz', 'Quellen'],
  contentUnitUrl: 'chapter',
  noNextChapterPreview: false,
  noCover: false,
  chaptersSortingFunction: (ch1: IChapter, ch2: IChapter) => ch1.idx - ch2.idx,
  extraRoutes: [],
  useFallbackBackgrounds: false,
  touchSwipeToSwitchChaptersDisabled: 'none',
  isVideobook: true,
  videoCustomization: {},
  restrictedScreens: {},
}

export const appConfig: ConfigType = defaultConfig

export const fullTheme: ThemeType = defaultTheme

export async function loadConfig() {
  const config: ConfigType | null = await importFromProject('config.ts')
  if (config) {
    Object.assign(appConfig, config)
  }

  const projectTheme: ThemeType | null = await importFromProject('theme.ts')
  if (projectTheme) {
    Object.assign(fullTheme, projectTheme)
  } else {
    // For secondary projects like Dufour, Stauffacher, etc. use Escher theme
    Object.assign(fullTheme, escherTheme)
  }
}
