import bind from 'bind-decorator'
import { action, computed, observable } from 'mobx'
import { getContentStore } from './contentStore/getContentStore'

class Slider {
  @observable currentSlideIdx: number = -1
  @observable isCurrentlyScrolling: boolean = false
  @observable showContent: boolean = false
  @observable chapterNumberVisible: boolean = false
  @observable loadingScreenHidden: boolean = true
  @observable chaptersBarVisible: boolean = true

  @bind
  @action
  setChapterNumberVisibility(value: boolean) {
    this.chapterNumberVisible = value
  }

  @bind
  @action
  setChaptersBarVisibility(value: boolean) {
    this.chaptersBarVisible = value
  }

  @computed
  get currentChapter() {
    const contentStore = getContentStore()
    if (!contentStore) {
      return
    }
    if (
      this.currentSlideIdx < 0 ||
      this.currentSlideIdx >= contentStore.chapters.length
    ) {
      return null
    }
    return contentStore.chapters[this.currentSlideIdx]
  }

  @bind
  @action
  setCurrentSlideIdx(idx: number) {
    this.currentSlideIdx = idx
  }

  @bind
  @action
  setShowContent(value: boolean) {
    this.showContent = value
  }

  @bind
  @action
  setSpinnerHidden(value: boolean) {
    this.loadingScreenHidden = value
  }
}

export const sliderStore = new Slider()

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.sliderStore = sliderStore
