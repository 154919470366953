import { WithStyles, withStyles, WithTheme } from '@material-ui/styles'
import bind from 'bind-decorator'
import classnames from 'classnames'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { SmartSwipeOverlay } from 'components/common/elements/overlays/SmartSwipeOverlay'
import withHover, {
  HoveredComponentProps,
  PropsWithClassname,
} from 'components/HOC/withHover'
import React from 'react'
import { breakpoints } from 'stores/breakpointsStore'
import { ThemeType } from 'theme'
import { importFromProject } from 'utils/importHelper'
import { InlineSubcontentWidgetStyles } from './inlineSubcontentWidget.style'
import { ModulesAndComponentsLoader } from 'modules/ModulesLoader'

interface IInlineSubcontentWidgetProps
  extends WithStyles<typeof InlineSubcontentWidgetStyles>,
    HoveredComponentProps,
    PropsWithClassname {
  thumbnail?: string
  icon?: string
  children?: JSX.Element
  handleClick: () => void
  subcontentId?: string
}

@observer
class InlineSubcontentWidget extends React.Component<
  IInlineSubcontentWidgetProps & WithTheme<ThemeType>
> {
  @observable.ref
  InlineWidgetComponent: React.ComponentType | null | undefined = undefined

  componentDidMount() {
    this.loadInlineWidget().catch((err) => console.error(err))
  }

  async loadInlineWidget() {
    const module: React.ComponentType | null = await importFromProject(
      'subcontent_modules/InlineSubcontentWidget'
    )
    this.setThumbnail(module)
  }

  @bind
  @action
  setThumbnail(Component: React.ComponentType | null) {
    this.InlineWidgetComponent = Component
  }

  render() {
    const {
      children,
      classes,
      subcontentId,
      thumbnail,
      icon,
      theme,
      isHovered,
    } = this.props

    if (this.InlineWidgetComponent) {
      // If we pass the classes, Material-UI throws warnings about overriding classes
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { classes, ...rest } = this.props
      return <this.InlineWidgetComponent {...rest} />
    }

    if (this.InlineWidgetComponent === undefined) {
      return null
    }

    return (
      <SmartSwipeOverlay
        className={classes.container}
        handleClick={this.props.handleClick}
      >
        <div className={classes.left}>
          <ModulesAndComponentsLoader
            moduleName="ThumbnailWithIcon"
            isModule={false}
            {...{
              hoverContainerClassName: classes['hover-container'],
              thumbnail,
              icon,
              subcontentId,
              overlayColor: breakpoints.desktop
                ? theme.palette.sectionText.desktop.accentColor(0.3)
                : theme.palette.sectionText.phone.accentColor(0.3),

              isHovered,
            }}
          />
        </div>
        <div
          className={classnames(classes.right, {
            [classes['right-hovered']]: isHovered,
          })}
        >
          <div
            className={classnames(classes['children-container'], {
              [classes['children-container-hovered']]: isHovered,
            })}
          >
            {children}
          </div>
        </div>
      </SmartSwipeOverlay>
    )
  }
}

export const StyledInlineWidget = withStyles(InlineSubcontentWidgetStyles, {
  name: 'InlineSubcontentWidget',
  withTheme: true,
})(InlineSubcontentWidget)

export default withHover(StyledInlineWidget)
