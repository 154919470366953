import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'

export const TopBarButtonsStyles = (_theme: ThemeType) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignSelf: 'flex-start',
      zIndex: 2,
      alignItems: 'center',
      position: 'relative',
    },
  })
