import { AnyObject } from './types'
import DOMPurify, { Config } from 'dompurify'
import { replaceRegexWithShyDashes } from './string'

export function safeHtml(html: string, config?: Config) {
  const htmlWithShyDashes = replaceRegexWithShyDashes(html)
  return {
    dangerouslySetInnerHTML: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __html: DOMPurify.sanitize(htmlWithShyDashes, config ?? {}) as string,
    },
  }
}

export function isNotNullOrUndefined<T>(
  value: T
): value is Exclude<T, undefined | null> {
  return value !== null && value !== undefined
}

export function isDefined(value: AnyObject) {
  return value !== undefined
}

export function isProject(project: string) {
  return project === process.env.REACT_APP_PROJECT_NAME
}
