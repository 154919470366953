import * as Prismic from '@prismicio/client'
import { PrismicAdapter } from 'stores/prismicAdapter'

import fetchHub, {
  IFetchHubReturnType,
  ParseHubForExtraDataFunctionType,
} from 'stores/hub/fetchHub'

import {
  FilmraumDocument,
  MapDocument,
  NavigationItemDocument,
  SchauraumDocument,
  TooltipDocument,
} from 'generated/types/heinrichdanioth.types'
import { languageStore } from 'stores/languageStore'

import { appConfig } from 'config'
import { runInAction } from 'mobx'
import { IChapter } from 'stores/contentStore/contentTypes'
import * as prismicHelper from 'stores/prismicHelpers'
import { IHub } from 'stores/types/hub'
import { parseMoreInfoTexts } from './prismicAdapter/helpers'
import { parseSchauraum, parseTooltips } from './prismicHelpers'
import { parseUIElementLegalMenu } from '../../../stores/prismicHelpers'

export class DaniothPrismicAdapter extends PrismicAdapter {
  async fetchAussenraum() {
    const api = await this._api.promise
    const results = (
      await api.query<MapDocument>(
        Prismic.Predicates.at('document.type', 'map'),
        {
          lang: languageStore.language,
        }
      )
    ).results

    if (results.length !== 1) {
      throw Error('Invalid number of Maps')
    }
    return results[0]
  }

  async fetchSchauraum() {
    const api = await this._api.promise
    let results: SchauraumDocument[] = []
    try {
      results = (
        await api.query<SchauraumDocument>(
          Prismic.Predicates.at('document.type', 'schauraum'),
          {
            lang: languageStore.language,
          }
        )
      ).results
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      return null
    } else {
      return parseSchauraum(results)
    }
  }

  async fetchTooltips() {
    const api = await this._api.promise
    let results: TooltipDocument[] = []
    try {
      results = await api.getAllByType<TooltipDocument>('tooltip', {
        lang: languageStore.language,
      })
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      return null
    } else {
      return parseTooltips(results)
    }
  }

  async fetchExtraChapters() {
    const api = await this._api.promise
    const extraChapters: IChapter[] = []
    for (const language of languageStore.languages) {
      const lang = language.code

      const resultPromises = appConfig.prismicModelsNamesMap.extraChapters?.map(
        async (_, index) => {
          return (
            await api.query(
              Prismic.Predicates.at(
                'document.type',
                `${
                  appConfig.prismicModelsNamesMap.extraChapters?.[index] ??
                  'biography'
                }`
              ),
              {
                lang,
              }
            )
          ).results[0]
        }
      )
      if (!resultPromises) {
        return null
      }
      const results = await Promise.all(resultPromises)
      if (!results[0]) {
        return null
      }
      for (const chapter of results) {
        runInAction(() => {
          const newChapter = prismicHelper.parseChapter(chapter)
          extraChapters.push(newChapter)
        })
      }
      return extraChapters
    }
  }

  async fetchNavigation(): Promise<NavigationItemDocument[]> {
    const results = await this._getAllResults<NavigationItemDocument>(
      Prismic.Predicates.at('document.type', 'navigation-item'),
      { ...this.defaultFetchOption }
    )
    return results
  }

  async fetchFilmraum(): Promise<FilmraumDocument> {
    const api = await this._api.promise
    const results: FilmraumDocument = (
      await api.query<FilmraumDocument>(
        Prismic.Predicates.at('document.type', 'filmraum'),
        {
          lang: languageStore.language,
        }
      )
    ).results[0]

    return results
  }

  async fetchHub(): Promise<IHub | null>
  async fetchHub<T extends IHub>(
    parseHubForExtraData: ParseHubForExtraDataFunctionType<T>
  ): Promise<T | null>
  async fetchHub<T extends IHub>(
    parseHubForExtraData?: ParseHubForExtraDataFunctionType<T>
  ): Promise<T | IHub | null> {
    const result: IFetchHubReturnType | null = await fetchHub(this)
    if (!result) {
      return null
    }
    const { hub, data } = result
    if (parseHubForExtraData === undefined) {
      return hub
    } else {
      return parseHubForExtraData(hub, data)
    }
  }

  // cannot abstract method into separate file because super is needed to be called
  async fetchUIElements() {
    const result = super.fetchUIElements([
      { forType: 'More Info', handlerFunc: parseMoreInfoTexts },
      { forType: 'legal_menu', handlerFunc: parseUIElementLegalMenu },
    ])

    return result
  }
}

export const prismicAdapter = new DaniothPrismicAdapter()
