import { isFilled } from '@prismicio/helpers'
import {
  FilledLinkToDocumentField,
  FilledLinkToWebField,
} from '@prismicio/types'
import {
  FilmraumDocument,
  MapDocument,
  MapDocumentDataSectionsItem,
  NavigationItemDocument,
  SchauraumDocument,
  SchauraumDocumentDataBannersItem,
  TooltipDocument,
} from 'generated/types/heinrichdanioth.types'
import * as PrismicDOM from '@prismicio/helpers'
import { IChapter } from 'stores/contentStore/contentTypes'
import { IAussenraum } from './types/aussenraum'
import { IFilmraum } from './types/filmraum'
import { INavigationItem, NavigationItem } from './types/navigation'
import { ISchauraum } from './types/schauraum'
import { TooltipItem } from './types/tooltipItem'

interface IMetaChapterData {
  name: string
  url: string
  idx: number
}

export const parseBanner = (banner: SchauraumDocumentDataBannersItem) => {
  return {
    buttonText: banner.button_text ?? '',
    description: PrismicDOM.asHTML(banner.description_rich_text) ?? '',
    title: banner.title ?? '',
    image: banner.image.url ?? '',
    url: (banner.link as FilledLinkToDocumentField).url ?? '',
  }
}

export const parseDaniothAussenraum = (map: MapDocument): IAussenraum => {
  return {
    text: map.data.text ?? '',
    title: map.data.title ?? '',
    image: map.data.image.url ?? '',
    sections: (map.data.sections as MapDocumentDataSectionsItem[]).map(
      (section) => {
        return {
          image: section.image.url ?? '',
          link: (section.link as FilledLinkToWebField).url ?? '',
          title: section.title ?? '',
          text: section.text ?? '',
          buttonText: section.button_text ?? '',
        }
      }
    ),
  }
}

export const parseNavigation = (
  results: NavigationItemDocument[],
  chapters: IMetaChapterData[]
): INavigationItem[] => {
  const navigationItems = results.map((document) => {
    const data = document.data

    if (data && document?.uid && data.order_no === 1 && chapters.length) {
      document.uid = `${document.uid}/${chapters[0].url}`
    }
    return {
      name: data.name ?? '',
      slug: document.uid.replace('-', '/') ?? '',
      order: data.order_no ?? -1,
    }
  })

  const hubNavigationItem = new NavigationItem()
  hubNavigationItem.order = 0
  hubNavigationItem.name = 'Übersicht'
  hubNavigationItem.slug = 'hub'
  navigationItems.push(hubNavigationItem)

  return navigationItems
}

export const parseChapterTitleAndUid = ({
  data,
}: {
  data: IChapter[]
}): IMetaChapterData[] => {
  return data.map((chapter) => {
    return {
      name: chapter.title,
      url: chapter.uid,
      idx: chapter.idx,
    }
  })
}

export const parseDaniothFilmraum = ({ data }: FilmraumDocument): IFilmraum => {
  return {
    title: data.title ?? '',
    description: data.description ?? '',
    image: {
      url: data.image.url ?? '',
      altImage: data.image.alt ?? '',
    },
    meta: data.meta ?? '',
    external: {
      text: data.outside[0]?.text ?? '',
      link: (data.outside[0]?.link as FilledLinkToWebField).url ?? '',
    },
    film: {
      text: data.film[0]?.text ?? '',
      link: isFilled.contentRelationship(data.film[0]?.link)
        ? data.film[0]?.link
        : undefined,
    },
  }
}

export const parseSchauraum = (
  schauraumDocument: SchauraumDocument[]
): ISchauraum => {
  const data = schauraumDocument[0].data
  const schauraum: ISchauraum = {
    title: (data.title && PrismicDOM.asText(data.title)) ?? '',
    secondaryTitle: data.secondary_title ?? '',
    searchPlaceholder: data.search_placeholder ?? '',
    arrowDownText: data.arrow_down_text ?? '',
    loadMoreText: data.load_more_text ?? '',
    featured1Title: data.featured_1_title ?? '',
    featured1Teaser: data.featured_1_teaser ?? '',
    featured2Title: data.featured_2_title ?? '',
    featured2Teaser: data.featured_2_teaser ?? '',
    recentArtworksTitle: data.recent_artworks_title ?? '',
    banners: (data.banners as []).map((banner) => parseBanner(banner)),
  }
  return schauraum
}

export const parseTooltips = (
  tooltipDocuments: TooltipDocument[]
): TooltipItem[] => {
  return tooltipDocuments.map((singleTooltip) => {
    return {
      id: singleTooltip.id,
      title: PrismicDOM.asText(singleTooltip.data.title),
      description: PrismicDOM.asHTML(singleTooltip.data.description),
    }
  })
}
