import bind from 'bind-decorator'
import { action, observable, runInAction, toJS } from 'mobx'
import { list, object, serializable, update } from 'serializr'

import { getAdapter } from 'stores/adapterHelper'
import { ISubchapter } from 'stores/contentStore/contentTypes'
import { ContentStore } from 'stores/contentStore/globalStore'
import { ParseHubForExtraDataFunctionType } from 'stores/hub/fetchHub'
import { HubWithRooms, IExtendedHub, IHub } from 'stores/types/hub'

import { DaniothPrismicAdapter } from './prismicAdapter'
import {
  parseChapterTitleAndUid,
  parseDaniothAussenraum,
  parseDaniothFilmraum,
  parseNavigation,
} from './prismicHelpers'

import { Aussenraum } from './types/aussenraum'
import { Filmraum } from './types/filmraum'
import { Navigation } from './types/navigation'
import { Schauraum } from './types/schauraum'
import { TooltipItem } from './types/tooltipItem'

type ExtraData = IExtendedHub

export class DaniothContentStore extends ContentStore {
  prismicAdapter = getAdapter() as DaniothPrismicAdapter

  @serializable(object(Schauraum))
  @observable
  schauraum: Schauraum | null = null

  @serializable(object(Aussenraum))
  @observable
  aussenraum: Aussenraum | null = null

  @serializable(object(Filmraum))
  @observable
  filmraum: Filmraum | null = null

  @serializable(object(Navigation))
  @observable
  navigation: Navigation | null = null

  @serializable(object(HubWithRooms))
  @observable
  hub: HubWithRooms | null = null

  @serializable(list(object(TooltipItem)))
  @observable
  tooltips: TooltipItem[] | null = null

  @observable
  nestedMenuOpen = false

  async fetchHub(): Promise<IHub>
  async fetchHub<T extends IHub>(
    parseHubForExtraData?: ParseHubForExtraDataFunctionType<T>
  ): Promise<T>
  async fetchHub<T extends IHub>(
    parseHubForExtraData?: ParseHubForExtraDataFunctionType<T>
  ) {
    if (parseHubForExtraData) {
      const hub: T | null = await this.prismicAdapter.fetchHub(
        parseHubForExtraData
      )
      runInAction(() => {
        update(DaniothContentStore, this, { hub })
      })
      return hub
    } else {
      const hub: IHub | null = await this.prismicAdapter.fetchHub()
      runInAction(() => {
        update(DaniothContentStore, this, { hub })
      })
      return hub
    }
  }

  async fetchData(
    parseHubForExtraData?: ParseHubForExtraDataFunctionType<ExtraData>
  ) {
    const glossary = await this.prismicAdapter.fetchGlossary()
    const hub = await this.fetchHub(parseHubForExtraData)

    runInAction(() => {
      update(DaniothContentStore, this, { glossary })
      update(DaniothContentStore, this, { hub })
    })

    await this.fetchUIElems()
  }

  async fetchAussenraum() {
    const aussenraum = parseDaniothAussenraum(
      await this.prismicAdapter.fetchAussenraum()
    )
    runInAction(() => {
      update(DaniothContentStore, this, { aussenraum })
    })
  }

  async fetchSchauraum() {
    const schauraum = await this.prismicAdapter.fetchSchauraum()
    runInAction(() => {
      update(DaniothContentStore, this, {
        schauraum,
      })
    })
  }

  async fetchTooltips() {
    const tooltips = await this.prismicAdapter.fetchTooltips()
    runInAction(() => {
      update(DaniothContentStore, this, {
        tooltips,
      })
    })
  }

  async fetchNavigation() {
    const chapterItems = parseChapterTitleAndUid(
      await this.prismicAdapter.fetchChapters()
    ).sort((a, b) => a.idx - b.idx)

    const navigationItems = parseNavigation(
      await this.prismicAdapter.fetchNavigation(),
      chapterItems
    )

    runInAction(() => {
      update(DaniothContentStore, this, {
        navigation: { navigationItems, chapterItems },
      })
    })
  }

  async fetchExtraChapters() {
    const extraChapters = await this.prismicAdapter.fetchExtraChapters()
    runInAction(() => {
      update(DaniothContentStore, this, { extraChapters })
    })
    let subchapters: Array<ISubchapter | null> = []
    if (this.extraChapters?.[0]) {
      for (const chapter of this.extraChapters) {
        subchapters = await this.prismicAdapter.fetchSubchapters(
          toJS(chapter.subchaptersIds)
        )
      }
    }

    runInAction(() => {
      update(DaniothContentStore, this, { subchapters })
    })

    const subcontentPromises = []
    const subcontentIds = []
    if (subchapters?.[0]) {
      for (const subchapter of subchapters) {
        if (subchapter?.backgroundId) {
          subcontentPromises.push(
            this.fetchSubcontentById(subchapter.backgroundId)
          )
          subcontentIds.push(subchapter.backgroundId)
        }
      }
    }

    await Promise.all(subcontentPromises)
    const subcontentsLoaded = subcontentIds.map((subcontentId) =>
      this.fullscreenSubcontent.get(subcontentId)
    )
    if (subcontentsLoaded[0]) {
      for (const subcontent of subcontentsLoaded) {
        if (subcontent) {
          subcontent.resolvePromiseAndLoadResources()
        }
      }
    }
  }

  async fetchFilmraum() {
    const filmraum = parseDaniothFilmraum(
      await this.prismicAdapter.fetchFilmraum()
    )
    runInAction(() => {
      update(DaniothContentStore, this, { filmraum })
    })
  }

  @bind
  @action
  displayNestedMenu(open: boolean) {
    this.nestedMenuOpen = open
  }
}

export const contentStore = new DaniothContentStore()
