import React from 'react'
import { Subchapter } from 'stores/contentStore/contentTypes'
import { SectionItem } from 'stores/contentStore/sectionItems/SectionItem'
import { AnyObject } from 'utils/types'
import { ScrollPageChild } from '../common/ScrollPage'
import SubchapterComponent from 'components/Section/SectionWrapper'

class EmptyConstructor extends ScrollPageChild<AnyObject> {}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ContentFactory {
  static build(data: Subchapter) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let component: any = null
    switch (data.type) {
      case 'subchapter':
        component = SubchapterComponent
        break
      default:
        component = EmptyConstructor
        break
    }

    return component
  }
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SubcontentFactory {
  static build(
    data: SectionItem,
    counter: number,
    subchapterId: string,
    ref: React.RefObject<HTMLDivElement>
  ) {
    return data.createInlineElement(counter, subchapterId, ref)
  }
}
