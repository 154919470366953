import bind from 'bind-decorator'
import { action, computed, observable } from 'mobx'
import { setLocalStorageItem } from 'utils/localStorage'
import { appConfig } from './../config'
import { navigationStore } from './navigationStore'
import { decodingRouterHelper } from './routerStore'

class CurrentStory {
  @observable activeSectionId: string | null = null
  @observable scrollerData: number[] = []
  @observable.ref fastScrollerData: { [id: string]: number } = {}
  @observable swipeIsBlocked: boolean = false
  @observable contentTableOpen: boolean = false
  @observable printDiv: HTMLDivElement | null = null
  @observable sideBarIsShown: boolean = false

  @computed
  get useFallbackBackgrounds() {
    if (!appConfig.useFallbackBackgrounds) {
      return false
    }
    const params = decodingRouterHelper.qsParsed
    if (params.useFallback) {
      return true
    }
    return false
  }

  @bind
  @action
  setSidebarShown(value: boolean) {
    this.sideBarIsShown = value
  }

  @bind
  @action
  setBlocking(value: boolean) {
    this.swipeIsBlocked = value
  }

  @computed
  get vertiefungExpanded() {
    const parsed = decodingRouterHelper.qsParsed
    return !!(parsed.fullscreen ?? parsed.overlay)
  }

  @bind
  @action
  setScrollerData(data: number[]) {
    this.scrollerData = data
  }

  @bind
  @action
  setFastScrollerData(data: { [id: string]: number }) {
    this.fastScrollerData = data
  }

  @bind
  @action
  setActiveSectionId(value: string) {
    this.activeSectionId = value
  }

  quitFullscreen() {
    const params = decodingRouterHelper.qsParsed
    params.fullscreen = null
    navigationStore.replaceParams(params, true)
  }

  @bind
  @action
  toggleContentTable() {
    this.contentTableOpen = !this.contentTableOpen
  }

  @bind
  @action
  setUseFallbackBackgrounds(value: boolean) {
    setLocalStorageItem({ useFallback: true })
    if (value) {
      const params = decodingRouterHelper.qsParsed
      params.useFallback = 'true'
      navigationStore.replaceParams(params, false)
      return
    }
    const params = {
      ...decodingRouterHelper.qsParsed,
      ...navigationStore.clearQueryParams(['useFallback']),
    }
    navigationStore.replaceParams(params, false)
    return
  }
}

export const currentStory = new CurrentStory()

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.currentStory = currentStory
