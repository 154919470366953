import { serializable, primitive, object, list } from 'serializr'
import { observable } from 'mobx'

export interface ILetterWord {
  title: string
  titleMultiline: string
  text: string
}

export class LetterWord implements ILetterWord {
  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  titleMultiline: string = ''

  @serializable
  @observable
  text: string = ''
}

export interface ILetter {
  letter: string
  items: ILetterWord[]
}

export interface IGlossary {
  id: string
  uid: string
  letters: ILetter[]
}

export class Letter implements ILetter {
  @serializable
  @observable
  letter: string = ''

  @serializable(list(object(LetterWord)))
  @observable
  items: LetterWord[] = []
}

export class Glossary implements IGlossary {
  @serializable(primitive())
  @observable
  id: string = ''

  @serializable
  @observable
  uid: string = ''

  @serializable(list(object(Letter)))
  @observable
  letters: Letter[] = []
}
