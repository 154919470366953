import { observable } from 'mobx'
import { list, object, primitive, serializable } from 'serializr'

export class Banner {
  @serializable
  @observable
  buttonText: string = ''

  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  description: string = ''

  @serializable
  @observable
  image: string = ''

  @serializable
  @observable
  url: string = ''
}

export interface ISchauraum {
  title: string
  secondaryTitle: string
  searchPlaceholder: string
  arrowDownText: string
  loadMoreText: string
  featured1Title: string
  featured1Teaser: string
  featured2Title: string
  featured2Teaser: string
  recentArtworksTitle: string
  banners: Banner[] | null
}

export class Schauraum implements ISchauraum {
  @serializable(primitive())
  @observable
  title: string = ''

  @serializable(primitive())
  @observable
  secondaryTitle: string = ''

  @serializable(primitive())
  @observable
  searchPlaceholder: string = ''

  @serializable(primitive())
  @observable
  arrowDownText: string = ''

  @serializable(primitive())
  @observable
  loadMoreText: string = ''

  @serializable(primitive())
  @observable
  featured1Title: string = ''

  @serializable(primitive())
  @observable
  featured1Teaser: string = ''

  @serializable(primitive())
  @observable
  featured2Title: string = ''

  @serializable(primitive())
  @observable
  featured2Teaser: string = ''

  @serializable(primitive())
  @observable
  recentArtworksTitle: string = ''

  @serializable(list(object(Banner)))
  @observable
  banners: Banner[] | null = null
}
