import { withStyles, WithStyles } from '@material-ui/styles'
import bind from 'bind-decorator'
import classnames from 'classnames'
import { Throttle } from 'lodash-decorators/throttle'
import {
  action,
  computed,
  IReactionDisposer,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { breakpoints, IBreakpoints } from 'stores/breakpointsStore'
import { contentStore } from 'stores/contentStore/contentStore'
import { IChapter, Subchapter } from 'stores/contentStore/contentTypes'
import { currentStory } from 'stores/currentStoryStore'
import { eventEmitter } from 'stores/eventEmitter'
import { menuStore } from 'stores/menuStore'
import { MatchParams, navigationStore } from 'stores/navigationStore'
import { sliderStore } from 'stores/sliderStore'
import { topBarStore } from 'stores/topBarStore'
import { windowStore } from 'stores/windowStore'
import { AnyObject } from 'utils/types'
import { ScrollPageStyles } from './scrollPage.style'
import { SideBarProps } from '../ScrollSidebar'

export interface IScrollPageChildProps {
  scrollPos?: number
  realScrollPos?: number
  activeSection?: boolean
  scrollBy?: number
  scrollerData?: Array<number | null>
  scrollerRefs?: Map<string, HTMLDivElement>
  snapPoints?: number[]
  slideScrollHeight?: (breakpoints: IBreakpoints) => number
}

/**
 *
 *
 * @export
 * @class ScrollPageChild
 * @extends {StoreComponent<IScrollPageChildProps, S>}
 * @template S the context
 *
 * The class must be implemented from a scroll child
 */

export class ScrollPageChild<
  P = AnyObject,
  S = AnyObject
> extends React.Component<IScrollPageChildProps & P, S> {
  static snapPoints: number[] = [0]

  static slideScrollHeight(): number {
    return 100
  }
}

export type ComponentConstructor<P = AnyObject, S = AnyObject> = new (
  props: IScrollPageChildProps & P,
  context: S
) => ScrollPageChild<IScrollPageChildProps, S>

export interface ScrollChildren<P> {
  component: ComponentConstructor<P>
  props: P
}

interface IPartialSlide {
  id: string
}

interface IPartialSlideData {
  data: IPartialSlide
  active: boolean
  nextData?: IChapter | null
}

export type PartialSlideProps = IPartialSlide | IPartialSlideData

function hasId(obj: unknown): obj is IPartialSlide {
  return (obj as IPartialSlide).id !== undefined
}

function hasDataWithId(obj: unknown): obj is IPartialSlideData {
  return (
    (obj as IPartialSlideData).data &&
    (obj as IPartialSlideData).data.id !== undefined
  )
}

interface ScrollPageProps
  extends WithStyles<typeof ScrollPageStyles>,
    RouteComponentProps<MatchParams> {
  videoUrl169?: string
  videoUrl916?: string
  active?: boolean
  pageClassName?: string
  className?: string
  children: Array<ScrollChildren<PartialSlideProps> | undefined>
  sectionsIds: string[]
  sidenav: ScrollChildren<SideBarProps>
  slideIdx: number
  gotoNext?(): void
}

@observer
class ScrollPage extends React.Component<ScrollPageProps> {
  @observable scrollPosition: number = 0
  @observable nOfChildComponents = 0

  elems: Map<string, HTMLDivElement> = observable.map(new Map(), {
    deep: false,
  })

  elemStarts: Map<string, number> = observable.map(new Map(), { deep: false })
  elemEnds: Map<string, number> = observable.map(new Map(), { deep: false })

  scrollElement = React.createRef<HTMLDivElement>()
  printRef = React.createRef<HTMLDivElement>()

  sidenav = React.createRef<HTMLDivElement>()
  disposers: IReactionDisposer[] = []

  @observable
  orientationJustChanged = false

  @observable
  sizesRefreshed = false

  constructor(props: ScrollPageProps) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.setScrollPosition = this.setScrollPosition.bind(this)
    this.disposers.push(
      reaction(
        () => ({
          nOfRefs: this.elems.size,
          sectionUid: this.sectionUid,
          subcontentsFetched: this.subcontentsFetched,
          chapterLoaded: sliderStore.currentChapter?.sectionsLoaded,
          orientationJustChanged: this.orientationJustChanged,
          nOfChildComponents: this.nOfChildComponents,
        }),
        (data) => {
          if (!this.props.active) {
            return
          }
          if (!data.subcontentsFetched) {
            return
          }
          if (!data.chapterLoaded) {
            return
          }
          this.scrollToSectionWithUid(data.sectionUid)
          if (this.props.active && !this.sizesRefreshed) {
            this.refreshSizes()
            this.setFastScrollerData(this.scrollPosition)
            runInAction(() => {
              this.sizesRefreshed = true
            })
          }
        },
        {
          fireImmediately: true,
        }
      )
    )
  }

  @computed
  get sectionUid() {
    return this.props.match.params.sectionUid
  }

  @computed
  get subcontentsFetched() {
    const activeChapter = sliderStore.currentChapter
    if (!activeChapter) {
      return
    }
    const subchapters: Subchapter[] = []
    for (const id of activeChapter.subchaptersIds) {
      const subchapter = contentStore.subchapters.get(id)
      if (subchapter) {
        subchapters.push(subchapter)
      }
      if (subchapter?.uid === this.sectionUid) {
        break
      }
    }
    const subcontentsIds = subchapters?.map(
      (subchapter) => subchapter?.subcontentId
    )
    const subcontents = subcontentsIds.map((id) =>
      contentStore.fullscreenSubcontent.get(id)
    )
    const subcontentsUpToCurrentAlreadyFetched = subcontents.every(
      (subcontent) => subcontent !== undefined
    )
    return subcontentsUpToCurrentAlreadyFetched
  }

  render() {
    const slides = []
    const scrollerData = []
    let activeId = ''
    for (const [id, elemStart] of this.elemStarts) {
      const SAFETY_SCROLL_DIFFERENCE = 100
      const elemEnd = this.elemEnds.get(id) ?? -1
      if (
        (this.scrollPosition || this.scrollPosition === 0) &&
        (elemStart || elemStart === 0) &&
        elemEnd >= 0 &&
        this.scrollPosition >= elemStart - SAFETY_SCROLL_DIFFERENCE
      ) {
        activeId = id
      }
    }
    for (const [index, id] of this.props.sectionsIds.entries()) {
      const normalizer = this.getNormalizer(id, this.scrollPosition)
      // We return null if start of the element or its height are undefined.
      // So, e.g. for quizzes in Escher, since we don't show them on  mobile, we don't have start and end elements for quizzes section
      // hence check and return null. Otherwise scrollerData contains false-positive 0
      scrollerData.push(normalizer)
      // }
      const slide =
        this.props.sectionsIds.length === this.props.children.length
          ? this.props.children[index]
          : null
      slides.push(
        <div key={id} ref={this.assignRefs(id)} data-id={id}>
          {slide && (
            <slide.component
              scrollPos={normalizer}
              realScrollPos={this.scrollPosition}
              activeSection={id === activeId}
              {...slide.props}
              scrollerRefs={this.elems}
            />
          )}
        </div>
      )
    }
    const sidenav = this.props.sidenav
    const sidebar = (
      <div key="sidebar" ref={this.sidenav} style={{ width: 0 }}>
        <sidenav.component
          {...this.props.sidenav.props}
          scrollerData={scrollerData}
          scrollerRefs={this.elems}
        />
      </div>
    )

    const { classes } = this.props
    return (
      <div
        className={classnames(classes.page, this.props.pageClassName)}
        style={{ height: windowStore.height }}
      >
        {this.props.active && sidebar}
        <div className={classes['page-scrollbar-hider']}>
          <div
            className={classnames(
              classes['page-scroller'],
              currentStory.vertiefungExpanded || menuStore.open
                ? classes.noscroll
                : undefined
            )}
            onClick={this.props.gotoNext}
            ref={this.scrollElement}
          >
            <div className={classes['page-content']} ref={this.printRef}>
              {slides}
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    if (this.scrollElement.current) {
      this.scrollElement.current.addEventListener(
        'scroll',
        this.emitScrollEvent,
        true
      )
    }
    this.disposers.push(
      reaction(
        () => windowStore.orientationJustChanged,
        (render) => {
          if (render) {
            this.setOrientationChanged(true)
          }
        }
      )
    )
    eventEmitter.on('scrollpage-scroll', this.handleScroll)
    runInAction(() => {
      if (this.printRef.current && this.props.active) {
        currentStory.printDiv = this.printRef.current
      }
    })

    this.refreshSizes()
  }

  componentDidUpdate() {
    this.refreshSizes()
    if (this.props.active) {
      this.setFastScrollerData(this.scrollPosition)
      if (breakpoints.desktop) {
        if (this.scrollPosition < 500) {
          topBarStore.toggleExpansion(true)
        } else {
          topBarStore.toggleExpansion(false)
        }
      } else {
        menuStore.toggleChaptersBar(true)
      }
    }
  }

  componentWillUnmount() {
    if (this.scrollElement.current) {
      this.scrollElement.current.removeEventListener(
        'scroll',
        this.handleScroll
      )
    }
    for (const disposer of this.disposers) {
      disposer()
    }
    eventEmitter.off('scrollpage-scroll', this.handleScroll)
  }

  @bind
  handleOrientationChange() {
    if (windowStore.orientationJustChanged) {
      this.scrollToSectionWithUid(this.sectionUid)
    }
  }

  @bind
  scrollToSectionWithUid(sectionUid?: string) {
    let currentSection: Subchapter | null = null
    let divToScroll: HTMLDivElement | undefined
    for (const [, subchapter] of contentStore.subchapters) {
      if (subchapter && subchapter.uid === sectionUid) {
        currentSection = subchapter
        currentStory.setActiveSectionId(currentSection.id)
        divToScroll = this.elems.get(currentSection.id)
      }
    }
    if (divToScroll && !navigationStore.preventScroll) {
      divToScroll.scrollIntoView({
        block: 'start',
        inline: 'center',
        behavior: 'auto',
      })
      if (this.orientationJustChanged) {
        this.setOrientationChanged(false)
      }
    }
  }

  emitScrollEvent(e: Event) {
    eventEmitter.emit('scrollpage-scroll', e)
  }

  handleScroll(e: Event) {
    if (this.props.active && e.target) {
      const newPosition = Math.max(
        (e.target as HTMLDivElement).scrollTop,
        this.scrollElement.current?.scrollTop ?? 0
      )
      this.setScrollPosition(newPosition)
      if (this.props.active) {
        this.setFastScrollerData(newPosition)
      }
    }
    if (!this.orientationJustChanged) {
      navigationStore.blockScroll()
    }
  }

  @Throttle(200)
  setScrollPosition(value: number) {
    runInAction(() => {
      this.scrollPosition = value
    })
  }

  @bind
  setFastScrollerData(position: number) {
    const scrollerData: { [id: string]: number } = {}
    for (const slide of this.props.children as Array<
      ScrollChildren<PartialSlideProps>
    >) {
      let id: string = ''
      if (hasId(slide.props)) {
        id = slide.props.id
      } else if (hasDataWithId(slide.props)) {
        id = slide.props.data.id
      }
      const normalizer = this.getNormalizer(id, position)
      if (id && normalizer !== null) {
        scrollerData[id] = normalizer
      }
    }
    currentStory.setFastScrollerData(scrollerData)
  }

  @bind
  getNormalizer(id: string, scrollPosition: number) {
    let normalizer: number | null = 0
    if (this.elems.size > 0) {
      normalizer = this.normalizeScrollForElement(
        scrollPosition,
        this.elemStarts.get(id),
        this.elemEnds.get(id)
      )
    } else {
      normalizer = this.scrollPosition
        ? windowStore.height / this.scrollPosition
        : 0
    }
    return normalizer
  }

  normalizeScrollForElement(
    scrollPosition: number,
    start?: number,
    height?: number
  ) {
    if (start === undefined || height === undefined) {
      // Value 0 was working for some time. In case of new bugs rethink 0 vs null
      // return 0
      return null
    }
    // This happens when divs did not yet render to full height,
    // then they can have a negative offsetHeight
    if (start === 0 && height < 0) {
      // corresponds to very first section when chapter has no cover
      return 0
    }
    if (height < 0) {
      return -1 // return default -1, as if section is not in view yet
    }
    // 5px to be on a safe side because browsers calculate heights close to 0 differently
    const SAFE_NEAR_ZERO_SCROLL_POSITION = 5
    let normalizer =
      height > SAFE_NEAR_ZERO_SCROLL_POSITION
        ? (scrollPosition - start) / height
        : (scrollPosition - start) / windowStore.height + 1
    if (normalizer < -1) {
      normalizer = -1
    } else if (normalizer > 2) {
      normalizer = 2
    }
    return normalizer
  }

  @bind
  refreshSizes() {
    this.elemStarts = new Map()
    this.elemEnds = new Map()
    if (!this.sizesRefreshed) {
      runInAction(() => {
        this.nOfChildComponents = 0
      })
    }
    for (const id of this.elems.keys()) {
      const el = this.elems.get(id)
      if (el) {
        const child = el.firstChild as HTMLElement
        if (child) {
          if (!this.sizesRefreshed) {
            runInAction(() => {
              this.nOfChildComponents = this.nOfChildComponents + 1
            })
          }
          const elRect = el.getBoundingClientRect()
          this.elemStarts.set(id, el.offsetTop)
          this.elemEnds.set(id, elRect.height - windowStore.height)
        }
      }
    }
  }

  @bind
  @action
  assignRefs(id: string) {
    return action((element: HTMLDivElement) => {
      if (id) {
        this.elems.set(id, element)
        return this.elems.get(id)
      }
    })
  }

  @bind
  @action
  setOrientationChanged(value: boolean) {
    this.orientationJustChanged = value
  }
}

export default withStyles(ScrollPageStyles, { name: 'ScrollPage' })(
  withRouter(ScrollPage)
)
