import { appConfig } from 'config'
import { ModulesAndComponentsLoader } from 'modules/ModulesLoader'
import React, { Component } from 'react'

export default class NextChapterCover extends Component {
  render() {
    return (
      <ModulesAndComponentsLoader
        moduleName="NextChapterCover"
        isModule={false}
        loadDefaultVersion={!appConfig.noNextChapterPreview}
        {...this.props}
      />
    )
  }
}
