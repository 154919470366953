import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'
import { MOBILE } from 'utils/styles'

export const ContentSliderStyles = (theme: ThemeType) =>
  createStyles({
    container: {
      maxWidth: '100%',
      width: '100%',
      height: '100%',
    },
    'logo-container': {
      background: '#212121',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 2,
    },
    'logo-invisible': {
      height: 0,
      display: 'none',
    },
    'swipeable-views-container': {
      width: '100%',
      height: '100%',
    },
    burger: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent',
      width: theme.constants.sizes.desktop.bottomBarHeight,
      height: theme.constants.sizes.desktop.bottomBarHeight,
      zIndex: 2590,
      position: 'fixed',
      bottom: 0,
      left: 0,
      [`@media ${MOBILE}`]: {
        width: theme.constants.sizes.phone.bottomBarHeight,
        height: theme.constants.sizes.phone.bottomBarHeight,
        bottom: '',
        top: 0,
      },
    },
  })
