import { ModulesAndComponentsLoader } from 'modules/ModulesLoader'
import React, { Component } from 'react'

export default class ScrollSidebar extends Component {
  render() {
    return (
      <ModulesAndComponentsLoader
        moduleName="ScrollSidebar"
        isModule={false}
        {...this.props}
      />
    )
  }
}
