export const setLocalStorageItem = (item: { [id: string]: unknown }) => {
  if (localStorage) {
    for (const key in item) {
      localStorage.setItem(key, JSON.stringify(item[key]))
    }
  }
}

export function getLocalStorageItem<T>(item: string): T | null {
  if (localStorage) {
    const result = localStorage.getItem(item)
    if (result) {
      try {
        return JSON.parse(result)
      } catch {
        return null
      }
    } else {
      return null
    }
  } else {
    return null
  }
}

export function clearLocalStorageItem(item: string) {
  if (localStorage) {
    const result = localStorage.getItem(item)
    if (result) localStorage.removeItem(item)
  }
}
