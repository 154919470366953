import { bind } from 'bind-decorator'
import { action, observable } from 'mobx'
import qs from 'query-string'
import { routerStore } from './routerStore'
import { appConfig } from 'config'

export interface MatchParams {
  slideUid: string
  sectionUid?: string
  navExtraId?: string
  docType: string
  docUid: string
  docId: string
}

class NavigationStore {
  @observable preventScroll: boolean = false
  scrollUpdateTimeout = 300
  safeBlockingTime = 200
  timer: number = 0

  @bind
  @action
  blockScroll() {
    this.preventScroll = true
    window.clearTimeout(this.timer)
    this.timer = window.setTimeout(
      action(() => {
        this.preventScroll = false
        window.clearTimeout(this.timer)
      }),
      this.scrollUpdateTimeout + this.safeBlockingTime
    )
  }

  updateSectionUrl(params: Partial<MatchParams>, newSection: string) {
    const { slideUid, sectionUid } = params
    const search = routerStore.location.search
    if (sectionUid !== newSection) {
      routerStore.replace(
        `/${appConfig.contentUnitUrl}/${slideUid ?? ''}/${newSection}${search}`
      )
    }
  }

  clearQueryParams(params: string[]) {
    const result: { [param: string]: null } = {}
    for (const param of params) {
      result[param] = null
    }
    return result
  }

  @action
  replaceParams(params: qs.ParsedQuery, push?: boolean) {
    const newSearch = qs.stringify(params, {
      skipNull: true,
    })
    if ('?' + newSearch !== routerStore.location.search) {
      routerStore.location.search = newSearch
      if (push) routerStore.push(routerStore.location)
      else routerStore.replace(routerStore.location)
    }
  }
}

export const navigationStore = new NavigationStore()
