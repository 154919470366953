import bind from 'bind-decorator'
import { computed } from 'mobx'
import { breakpoints } from './breakpointsStore'
import { contentStore } from './contentStore/contentStore'
import { navigationStore } from './navigationStore'
import { decodingRouterHelper } from './routerStore'

export type ContentMenuTextTypes = 'impressum' | 'data-privacy'

class LegalStore {
  @computed
  get legalSection() {
    const parsed = decodingRouterHelper.qsParsed
    if (!parsed.legalSection) {
      return ''
    }
    return parsed.legalSection as string
  }

  @computed
  get showingLegal() {
    if (breakpoints.phone) {
      // For phone, show impressum even if no legal section is selected(all section closed)
      const parsed = decodingRouterHelper.qsParsed
      return parsed.legal
    }
    // For desktop, there is always one section selected, so show impressum only if something is selected, otherwise hide it
    if (this.legalSection) {
      return true
    }
    return false
  }

  @computed
  get quellenType() {
    const parsed = decodingRouterHelper.qsParsed
    if (!parsed) return ''
    return parsed.quellen as string
  }

  @bind
  toggleImpressum(section?: string) {
    const params = decodingRouterHelper.qsParsed
    params.legal = null
    params.legalSection = null
    params.quellen = null
    if (!this.showingLegal) {
      params.legal = 'open'
      params.legalSection = section ?? 'Impressum'
    }
    navigationStore.replaceParams(params, true)
  }

  selectLegalSection(section: string) {
    const params = decodingRouterHelper.qsParsed
    if (params.legalSection === section && breakpoints.phone) {
      // deselect section (i.e. close it) only on mobile
      params.legalSection = null
    } else {
      params.legalSection = section
      if (section === 'Quellen' && contentStore.quellen) {
        params.quellen = contentStore.quellen.sections[0].title
      } else {
        params.quellen = null
      }
    }
    navigationStore.replaceParams(params, true)
  }

  selectQuellenType(type: string) {
    const params = decodingRouterHelper.qsParsed
    params.quellen = type
    navigationStore.replaceParams(params, false)
  }
}

export const legalStore = new LegalStore()
