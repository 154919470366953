/* eslint-disable @typescript-eslint/naming-convention */
import { IPrismicParagraph, parseTextParagraphs } from 'stores/prismicHelpers'
import { beautifySubcontentTitle } from 'utils/string'
import * as PrismicDOM from '@prismicio/helpers'
import * as PrismicT from '@prismicio/types'
import { IImageData } from '../../subcontent_modules/subcontentInterfaces'
import { Dimensions, ResponsiveUrl } from 'stores/contentStore/contentTypes'

export interface IDimensions {
  width: number
  height: number
}
export interface IWithUrl {
  url: string
  alt?: string
  copyright?: string
  dimensions?: IDimensions
}

export interface DefaultBasePrismicSubcontentData {
  title?: PrismicT.RichTextField
  secondary_title?: PrismicT.RichTextField
  thumbnail?: IWithUrl
  is_overlay?: boolean
}

// Video
export interface IPrismicSubcontentVideoData
  extends DefaultBasePrismicSubcontentData {
  duration?: PrismicT.RichTextField
  mobile_url_video?: IWithUrl
  desktop_url_video?: IWithUrl
  description: PrismicT.RichTextField
  poster?: IResponsiveUrlPrismicData
  video_url_ie_11?: IWithUrl
  category?: string
  plays_inline?: boolean
}
export interface IPrismicSubcontentVideo {
  id: string
  uid: string
  data: IPrismicSubcontentVideoData
}

// Text

export interface IPrismicSubcontentTextData
  extends DefaultBasePrismicSubcontentData {
  lead_text: PrismicT.RichTextField
  background_image?: IWithUrl
  image_caption: PrismicT.RichTextField
  category?: string
  body: Array<{ items: IPrismicParagraph[] }>
  desktop_url_video_text_subcontent?: IWithUrl
}

export interface IPrismicSubcontentText {
  id: string
  uid: string
  data: IPrismicSubcontentTextData
}

// Image

export interface IPrismicSubcontentImageData
  extends DefaultBasePrismicSubcontentData {
  background_image?: IWithUrl
  image_description: PrismicT.RichTextField
  category?: string
}

export interface IPrismicSubcontentImage {
  id: string
  uid: string
  data: IPrismicSubcontentImageData
}

// Scrollytelling

export interface IPrismicSubcontentScrollytellingData
  extends DefaultBasePrismicSubcontentData {
  background_image?: IWithUrl
  infographics_file: IWithUrl
  fallback_nonwebp: IWithUrl
  portrait_yaml: IWithUrl
  portrait_yaml_nonwebp: IWithUrl
  animation_background: IWithUrl
  interaction_description: PrismicT.RichTextField
  height_of_section: number
  height_of_section_mobile: number
  category?: string
}

export interface IPrismicSubcontentScrollytelling {
  id: string
  uid: string
  data: IPrismicSubcontentScrollytellingData
}

// Graphics

export interface IPrismicSubcontentGraphicsData
  extends DefaultBasePrismicSubcontentData {
  background_image?: IWithUrl
  optional_graphics_image: IWithUrl
  link_to_interactive_file: IWithUrl
  interaction_description: PrismicT.RichTextField
  category?: string
}

export interface IPrismicSubcontentGraphics {
  id: string
  uid: string
  data: IPrismicSubcontentGraphicsData
}

// Gallery

export interface IPrismicSubcontentGalleryData
  extends DefaultBasePrismicSubcontentData {
  body: IImageSlice[]
  category?: string
}

export interface IPrismicSubcontentGallery {
  id: string
  uid: string
  data: IPrismicSubcontentGalleryData
}

interface IImageSlice {
  items: Array<{
    image: IWithUrl
    image_description: PrismicT.RichTextField
    image_title: PrismicT.RichTextField
  }>
}

// Quizzes

export interface IPrismicQuizzes {
  id?: string
  uid?: string
  data: {
    background?: IWithUrl
    title?: PrismicT.RichTextField
    category?: string
    quizzes?: Array<{
      link_to_quiz: {
        id: string
      }
    }>
  }
}

export const prismicDataParser = {
  video: (subcontent: IPrismicSubcontentVideo) => {
    if (!subcontent || !subcontent.data) {
      return
    }
    const data = subcontent.data
    return {
      id: subcontent.id ?? '',
      uid: subcontent.uid ?? '',
      type: 'video',
      title:
        (data.title &&
          beautifySubcontentTitle(PrismicDOM.asText(data.title))) ??
        '',
      secondaryTitle:
        (data.secondary_title && PrismicDOM.asText(data.secondary_title)) ?? '',
      duration: (data.duration && PrismicDOM.asText(data.duration)) ?? '',
      thumbnail: data.thumbnail?.url ?? '',
      mobileVideoUrl: data.mobile_url_video?.url ?? '',
      desktopVideoUrl: data.desktop_url_video?.url ?? '',
      description:
        (data.description && PrismicDOM.asHTML(data.description)) ?? '',
      poster: data.poster?.url ?? '',
      ieVideoUrl: data.video_url_ie_11?.url ?? '',
      openAsOverlay: data.is_overlay ?? false,
      category: data.category ?? '',
      playsAsBackground: data.plays_inline ?? false,
    }
  },
  text: (subcontent: IPrismicSubcontentText) => {
    if (!subcontent ?? !subcontent.data) {
      return
    }
    const data = subcontent.data

    return {
      id: subcontent.id ?? '',
      uid: subcontent.uid ?? '',
      type: 'text',
      title: beautifySubcontentTitle(PrismicDOM.asText(data.title)) ?? '',
      secondaryTitle:
        (data.secondary_title && PrismicDOM.asText(data.secondary_title)) ?? '',
      thumbnail: data.thumbnail?.url ?? '',
      leadText: PrismicDOM.asHTML(data.lead_text) ?? '',
      backgroundImage: data.background_image?.url ?? '',
      video: data.desktop_url_video_text_subcontent?.url ?? '',
      imageCaption: PrismicDOM.asText(data.image_caption) ?? '',
      textContent: parseTextParagraphs(data.body[0]?.items),
      openAsOverlay: data.is_overlay ?? false,
      category: data.category ?? '',
    }
  },
  image: (subcontent: IPrismicSubcontentImage) => {
    if (!subcontent || !subcontent.data) {
      return
    }
    const data = subcontent.data
    return {
      id: subcontent.id || '',
      uid: subcontent.uid || '',
      type: 'image',
      title: beautifySubcontentTitle(PrismicDOM.asText(data.title)) || '',
      secondaryTitle:
        (data.secondary_title && PrismicDOM.asText(data.secondary_title)) ?? '',
      thumbnail: data.background_image?.url ?? '',
      description:
        PrismicDOM.asHTML(
          data.image_description,
          (linkField) => linkField.id
        ) || '',
      openAsOverlay: data.is_overlay ?? false,
      category: data.category ?? '',
    }
  },
  scrollytelling: (subcontent: IPrismicSubcontentScrollytelling) => {
    if (!subcontent || !subcontent.data) {
      return
    }
    const data = subcontent.data
    return {
      id: subcontent.id || '',
      uid: subcontent.uid || '',
      type: 'scrollytelling',
      title: beautifySubcontentTitle(PrismicDOM.asText(data.title)) || '',
      secondaryTitle:
        (data.secondary_title && PrismicDOM.asText(data.secondary_title)) ?? '',
      thumbnail: data.thumbnail?.url ?? '',
      infoGraphicFile: data.infographics_file?.url || '',
      fallbackNonWebP: data.fallback_nonwebp?.url || '',
      portraitYaml: data.portrait_yaml?.url || '',
      portraitYamlNonWebP: data.portrait_yaml_nonwebp?.url || '',
      animationBackgroundImage: data.animation_background?.url || '',
      interactionDescription:
        PrismicDOM.asText(data.interaction_description) || '',
      heightOfSection: data.height_of_section || 1.5,
      heightOfSectionMobile:
        data.height_of_section_mobile || data.height_of_section_mobile || 2,
      openAsOverlay: data.is_overlay ?? false,
      category: data.category ?? '',
    }
  },
  graphics: (subcontent: IPrismicSubcontentGraphics) => {
    if (!subcontent || !subcontent.data) {
      return
    }
    const data = subcontent.data
    return {
      id: subcontent.id || '',
      uid: subcontent.uid || '',
      type: 'graphics',
      title: beautifySubcontentTitle(PrismicDOM.asText(data.title)) || '',
      secondaryTitle:
        (data.secondary_title && PrismicDOM.asText(data.secondary_title)) ?? '',
      thumbnail: data.thumbnail?.url ?? '',
      optionalImage: data.optional_graphics_image?.url || '',
      linkToInteractiveFile: data.link_to_interactive_file?.url || '',
      interactionDescription:
        PrismicDOM.asText(data.interaction_description) || '',
      openAsOverlay: data.is_overlay ?? false,
      category: data.category ?? '',
    }
  },
  gallery: (subcontent: IPrismicSubcontentGallery) => {
    if (!subcontent || !subcontent.data) {
      return
    }
    const data = subcontent.data
    return {
      id: subcontent.id || '',
      uid: subcontent.uid || '',
      type: 'gallery',
      title:
        (data.title &&
          beautifySubcontentTitle(PrismicDOM.asText(data.title))) ??
        '',
      secondaryTitle:
        (data.secondary_title && PrismicDOM.asText(data.secondary_title)) ?? '',
      thumbnail: data.thumbnail?.url ?? '',
      imagesCount: parseImages(data.body).count,
      images: parseImages(data.body).images || [],
      openAsOverlay: data.is_overlay ?? false,
      category: data.category ?? '',
    }
  },
  quizzes: (subcontent: IPrismicQuizzes) => {
    if (!subcontent ?? !subcontent.data) {
      return
    }
    const data = subcontent.data
    return {
      id: subcontent.id ?? '',
      type: 'quizzes',
      uid: subcontent.uid ?? '',
      thumbnail: data.background?.url ?? '',
      title: beautifySubcontentTitle(PrismicDOM.asText(data.title)) ?? 'Quiz',
      quizzesIds:
        data.quizzes?.map((quizItem) => quizItem.link_to_quiz.id) ?? [],
      category: data.category ?? '',
    }
  },
}

const parseImages = (imageSlices: IImageSlice[] = []) => {
  const images: IImageData[] = []
  let count = 0
  for (const imageSlice of imageSlices) {
    for (const imageData of imageSlice.items) {
      images.push({
        url: imageData.image?.url || '',
        description:
          (imageData.image_description &&
            PrismicDOM.asText(imageData.image_description)) ||
          '',
        title:
          (imageData.image_title && PrismicDOM.asText(imageData.image_title)) ||
          '',
      })
      count++
    }
  }
  return {
    images,
    count,
  }
}

interface IResolutionPrismicData {
  dimensions: Dimensions
  url: string
  alt?: string
}

export interface IResponsiveUrlPrismicData extends IWithUrl {
  alt?: string
  copyright: string
  url: string
  dimensions: Dimensions
  [resolution: string]: undefined | string | Dimensions | IResolutionPrismicData
}

export function resolutionDataIsObject(
  input: undefined | string | Dimensions | IResolutionPrismicData
): input is IResolutionPrismicData {
  return !!(input as IResolutionPrismicData)?.url
}

export const parseResponsiveUrls = (data: IResponsiveUrlPrismicData) => {
  const result: { [id: string]: ResponsiveUrl } = {}
  const defaultFields = ['dimensions', 'alt', 'copyright', 'url']
  for (const resolution in data) {
    const resolutionData = data[resolution]
    if (
      defaultFields.indexOf(resolution) < 0 &&
      resolutionDataIsObject(resolutionData)
    ) {
      result[resolution] = {
        dimensions: resolutionData.dimensions,
        url: resolutionData.url,
        alt: resolutionData.alt,
      }
    }
  }
  return result
}
