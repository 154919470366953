// TODO-Interface: Create interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseMoreInfoTexts = (item: any) => {
  const data = item.primary

  return {
    expandText: data.mehr,
    hideText: data.weniger,
  }
}
