import React from 'react'
import bind from 'bind-decorator'
import { action, observable } from 'mobx'
import { Subtract } from 'utility-types'
import { observer } from 'mobx-react'
import { breakpoints } from 'stores/breakpointsStore'
import classnames from 'classnames'
import './withHover.css'

export interface HoveredComponentProps {
  isHovered: boolean
}

export interface PropsWithClassname {
  hoverContainerClassName?: string
}

const withHover = <P extends HoveredComponentProps & PropsWithClassname>(
  WrappedComponent: React.ComponentType<P>
) => {
  @observer
  class HOC extends React.Component<Subtract<P, HoveredComponentProps>> {
    @observable
    isHovered: boolean = false

    @bind
    @action
    mouseEnter() {
      this.isHovered = true
    }

    @bind
    @action
    mouseLeave() {
      this.isHovered = false
    }

    render() {
      return (
        <div
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
          className={classnames(
            'HOC-hover-default',
            this.props.hoverContainerClassName
          )}
        >
          <WrappedComponent
            {...(this.props as P)}
            isHovered={breakpoints.desktop && this.isHovered}
          />
        </div>
      )
    }
  }

  return HOC
}

export default withHover
