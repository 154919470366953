import { MOBILE, DESKTOP, TABLET } from 'utils/styles'
import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'

export const InlineSubcontentWidgetStyles = (theme: ThemeType) =>
  createStyles({
    container: {
      height: theme.constants.sizes.desktop.standardButtonSize,
      display: 'flex',
      flexDirection: 'row',
      margin: '20px 0px',
      cursor: 'pointer',
      boxSizing: 'content-box',
      [`@media only screen and (orientation: landscape) and ${TABLET}`]: {
        height: theme.constants.sizes.tablet.standardButtonSize,
      },
      [`@media ${MOBILE}`]: {
        height: theme.constants.sizes.phone.standardButtonSize,
        margin: '26px 0',
      },
    },
    left: {
      width: theme.constants.sizes.desktop.standardButtonSize,
      position: 'relative',
      height: '100%',
      [`@media only screen and (orientation: landscape) and ${TABLET}`]: {
        width: theme.constants.sizes.tablet.standardButtonSize,
      },
      [`@media ${MOBILE}`]: {
        width: theme.constants.sizes.phone.standardButtonSize,
      },
    },
    right: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      boxSizing: 'border-box',
      paddingLeft: '21px',
      flexGrow: 1,
      backgroundImage:
        'linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))',
      transition: 'all 0.3s',
      '&>div': {
        fontSize: '0.95rem',
        lineHeight: 1.23,
        letterSpacing: '0.44px',
        textAlign: 'left',
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        fontFamily: theme.fonts.primary.sansSerif,
        fontWeight: 'bold',
        [`@media ${MOBILE}`]: {
          fontSize: '0.7rem',
        },
      },
    },
    'right-hovered': {
      [`@media ${DESKTOP}`]: {
        backgroundImage:
          'linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))',
        transition: 'all 0.3s',
      },
    },
    'children-container': {
      position: 'relative',
      left: 0,
      transition: 'left 0.3s',
    },
    'children-container-hovered': {
      left: 4,
      transition: 'left 0.3s',
    },
    'hover-container': {},
  })
