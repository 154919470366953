/* eslint-disable @typescript-eslint/no-explicit-any */

import * as Prismic from '@prismicio/client'
import * as PrismicDOM from '@prismicio/helpers'
import { PartialChapter } from 'stores/contentStore/contentTypes'
import { languageStore } from 'stores/languageStore'
import { PrismicAdapter } from 'stores/prismicAdapter'
import {
  createPartialChapter,
  IPrismicChapter,
  parseBackIssues,
} from 'stores/prismicHelpers'
import { IHub } from '../types/hub'

export interface IFetchHubReturnType {
  hub: IHub
  data: any
}

export type ParseHubForExtraDataFunctionType<T extends IHub> = (
  defaultHubData: IHub,
  fetchedData: any
) => T

async function fetchHub(
  prismicAdapter: PrismicAdapter
): Promise<IFetchHubReturnType | null> {
  const api = await prismicAdapter._api.promise
  let Hub: IFetchHubReturnType | null = null
  let results: any[] = []
  try {
    results = (
      await api.query(Prismic.Predicates.at('document.type', 'hub'), {
        lang: languageStore.language,
      })
    ).results
  } catch (error) {
    console.error(error)
  }
  if (!results || !results[0]) {
    Hub = null
  } else {
    const data = results[0].data
    const featuredData1 = await prismicAdapter.fetchById(
      data.featured_article_1.id
    )
    const featuredData2 = await prismicAdapter.fetchById(
      data.featured_article_2.id
    )
    const featuredArticlesTopBannerIds = data.featured_top_banner.map(
      (val: any) => val.featured_article_top_banner.id
    )
    const featured1: PartialChapter | null = featuredData1
      ? createPartialChapter(featuredData1.data as IPrismicChapter)
      : null
    const featured2: PartialChapter | null = featuredData2
      ? createPartialChapter(featuredData2.data as IPrismicChapter)
      : null

    const hub = parseHub(
      data,
      featuredArticlesTopBannerIds,
      featured1,
      featured2
    )
    Hub = { hub, data: results[0].data }
  }
  return Hub
}

type HubFeature = PartialChapter | null
// TODO-Interface: Create interface
export const parseHub = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  featuredArticlesTopBannerIds: string[],
  featured1: HubFeature,
  featured2: HubFeature
) => {
  return {
    dummyThumbnail: data.dummy_image.url,
    articlesLoaded: data.default_amount_of_articles_shown,
    featuredArticlesTopBannerIds,
    featuredArticle1: featured1,
    featuredArticle2: featured2,
    subscribeData: {
      type: 'subscribe' as const,
      text:
        data.subscribe_description.length > 0
          ? PrismicDOM.asHTML(data.subscribe_description) ?? ''
          : '',
      buttonText: data.button_text_subscribe,
      placeholderText: data.subscribe_input_placeholder_text,
      mailTo: data.mailto_address_button_subscribe,
      mailToSubject:
        (data.mailto_subject_subscribe &&
          PrismicDOM.asText(data.mailto_subject_subscribe)) ||
        '',
      mailToBody:
        (data.mailto_body_subscribe &&
          PrismicDOM.asText(data.mailto_body_subscribe)) ||
        '',
    },
    pensionersData: {
      type: 'pensioners' as const,
      text:
        data.subscribe_description.length > 0
          ? PrismicDOM.asHTML(data.pensioners_description) ?? ''
          : '',
      buttonText: data.button_text_pensioners,
      mailTo: data.mailto_address_button_pensioners,
      mailToSubject:
        (data.mailto_subject_pensioners &&
          PrismicDOM.asText(data.mailto_subject_pensioners)) ||
        '',
      mailToBody:
        (data.mailto_body_pensioners &&
          PrismicDOM.asHTML(data.mailto_body_pensioners)) ||
        '',
    },
    aboutLiveData: {
      type: 'about' as const,
      text:
        data.subscribe_description.length > 0
          ? PrismicDOM.asHTML(data.about_live) ?? ''
          : '',
      buttonText: data.button_text_about_live,
      mailTo: data.mailto_address_button_about_live,
      mailToBody:
        (data.mailto_subject_about_live &&
          PrismicDOM.asText(data.mailto_subject_about_live)) ||
        '',
    },

    backIssues: parseBackIssues(data.body) ?? [],
  }
}

export default fetchHub
