import { DESKTOP, giveColor, MOBILE } from 'utils/styles'
import './fonts/fonts.css'

export const diamondSizes = {
  desktop: {
    side: 160,
    diagonal: 160,
  },
  phone: {
    side: 100,
    diagonal: 140,
  },
  quiz: {
    side: 120,
    diagonal: 170,
  },
}

export const fontSize = {
  mobile: 2.6,
  desktop: 3.2,
}

const projectTheme = {
  palette: {
    white: (opacity?: number) => giveColor(255, 255, 255, opacity),
    black: (opacity?: number) => giveColor(0, 0, 0, opacity),
    secondary: {
      main: 'rgb(185, 31, 31)',
      dark: 'rgba(185, 31, 31, 0.7)',
      light: 'rgb(185, 31, 31, 0.3)',
    },
    primary: {
      main: '#ffffff',
      dark: 'rgba(255, 255, 255, 0.7)',
      light: 'rgba(255, 255, 255, 0.3)',
    },
    sectionText: {
      desktop: {
        background: 'transparent',
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
      phone: {
        background: giveColor(10, 10, 10, 0.95),
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
    sectionBackground: {
      desktop: {
        background: `linear-gradient(to left, ${giveColor(
          0,
          0,
          0,
          0.9
        )}, ${giveColor(0, 0, 0, 0)})`,
        backgroundReversed: `linear-gradient(to right, ${giveColor(
          0,
          0,
          0,
          0.9
        )}, ${giveColor(0, 0, 0, 0)})`,
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
      phone: {
        background: giveColor(0, 0, 0, 0.95),
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
    cover: {
      desktop: {
        background: `linear-gradient(to bottom, ${giveColor(
          0,
          0,
          0,
          0.3
        )}, ${giveColor(0, 0, 0, 0.6)})`,
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
      phone: {
        background: `linear-gradient(to bottom, ${giveColor(
          0,
          0,
          0,
          0.3
        )}, ${giveColor(0, 0, 0, 0.6)})`,
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
    // Videogalerie, Chronik, etc.
    materials: {
      desktop: {
        background: giveColor(255, 255, 255, 1.0),
        fontColor: (opacity?: number) => giveColor(0, 0, 0, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
      phone: {
        background: giveColor(255, 255, 255, 1.0),
        fontColor: (opacity?: number) => giveColor(0, 0, 0, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
    sideElements: {
      desktop: {
        background: 'linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0.9))',
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
      phone: {
        background: 'linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0.9))',
        fontColor: (opacity?: number) => giveColor(255, 255, 255, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
    quizzes: {
      desktop: {
        background: 'transparent',
        fontColor: (opacity?: number) => giveColor(0, 0, 0, opacity),
        accentColor: (opacity?: number) => giveColor(185, 31, 31, opacity),
        accentFontColor: (opacity?: number) =>
          giveColor(255, 255, 255, opacity),
      },
    },
  },
  constants: {
    sizes: {
      desktop: {
        topBarHeight: 80,
        sideBarWidth: 80,
        bottomBarHeight: 80,
        standardButtonSize: 80,
      },
      tablet: {
        topBarHeight: 80,
        sideBarWidth: 80,
        bottomBarHeight: 80,
        standardButtonSize: 80,
      },
      phone: {
        topBarHeight: 50,
        sideBarWidth: 15,
        bottomBarHeight: 46,
        standardButtonSize: 46,
        menuButtonSize: 54,
      },
    },
  },
  fonts: {
    primary: {
      serif: '"SRG SSR Type serif"',
      sansSerif: '"SRG SSR Type"',
    },
    secondary: {
      serif: '"Cookie"',
    },
  },
  overrides: {},
}

const overrides = {
  HeaderTitle: {
    'background-container': {
      left: '50%',
      top: `calc(-${diamondSizes.desktop.diagonal / 2}px + ${
        fontSize.desktop
      }rem)`,
      [`@media ${MOBILE}`]: {
        top: `calc(-${diamondSizes.phone.diagonal / 2}px + ${
          fontSize.mobile
        }rem)`,
      },
    },
    'chapter-number': {
      display: 'none',
    },
    background: {
      width: diamondSizes.desktop.side,
      height: diamondSizes.desktop.side,
      position: 'absolute',
      transform: 'rotate(45deg)',
      transformOrigin: 'top left',
      [`@media ${MOBILE}`]: {
        width: diamondSizes.phone.side,
        height: diamondSizes.phone.side,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: -10,
        left: -10,
        bottom: -10,
        right: -10,
        border: `3px solid ${projectTheme.palette.cover.desktop.accentColor(
          0.9
        )}`,
        [`@media ${MOBILE}`]: {
          borderColor: projectTheme.palette.cover.phone.accentColor(0.9),
        },
      },
    },
    title: {
      fontSize: `${fontSize.desktop}rem`,
      lineHeight: 1,
      [`@media ${DESKTOP}`]: {
        top: '1rem',
      },
      [`@media ${MOBILE}`]: {
        fontSize: `${fontSize.mobile}rem`,
        top: '-0.5rem',
      },
    },
  },
  ChaptersBar: {
    'bar-chapter-number': {
      display: 'none',
    },
  },
  TextSupportingMaterial: {
    scrollbar: {
      display: 'none',
    },
  },
  NextChapterCover: {
    'title-background-container': {
      top: `calc(-${diamondSizes.phone.diagonal / 2}px + ${
        fontSize.desktop
      }rem)`,
      [`@media ${MOBILE}`]: {
        top: `calc(-${diamondSizes.phone.diagonal / 2}px + ${
          fontSize.mobile
        }rem)`,
      },
    },
    'title-background': {
      width: diamondSizes.phone.side,
      height: diamondSizes.phone.side,
    },
  },
  TopBarTab: {
    tab: {
      padding: '0 2rem',
      '& *': {
        opacity: 0.7,
      },
      '&:hover': {
        '& *': {
          opacity: 1,
        },
      },
    },
  },
  ThumbnailWithIcon: {
    icon: {
      transition: 'all 0.3s',
      width: '60%',
      height: '60%',
    },
    'icon-hovered': {
      transform: 'scale(1.05)',
      transition: 'all 0.3s',
    },
  },
  BottomBarSlider: {
    'image-counter': {
      display: 'none',
    },
  },
  BottomBar: {
    container: {
      width: '100%',
    },
  },
  SectionTitle: {
    'title-container': {
      justifyContent: 'center',
    },
  },
  CookiesBanner: {
    container: {
      padding: 0,
      border: 'none',
    },
    'inner-container': {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    'text-content': {
      [`@media ${DESKTOP}`]: {
        textAlign: 'left',
        maxWidth: '50%',
      },
    },
    'accept-button': {
      [`@media ${DESKTOP}`]: {
        marginTop: 0,
      },
    },
  },
  ScrollSidebar: {
    'chapter-number': {
      display: 'none',
    },
  },
  ImpressumContainer: {
    logo: {
      display: 'none',
    },
  },
  VideoPlayer: {
    'player-wrapper': {
      '&&': {
        '& video': {
          [`@media ${MOBILE} and orientation: portrait`]: {
            objectFit: 'contain',
          },
        },
      },
    },
    'player-wrapper-overlay': {
      '& video': {
        backgroundColor: projectTheme.palette.black(),
      },
    },
  },
}

projectTheme.overrides = overrides

export default projectTheme
