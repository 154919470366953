import {
  FilledLinkToDocumentField,
  FilledLinkToMediaField,
  FilledLinkToWebField,
  LinkField,
  LinkType,
  RichTextField,
  TitleField,
  PrismicDocument,
} from '@prismicio/types'
import {
  UiElementsDocumentDataBodyLegalMenuSlice,
  UiElementsDocumentDataBodySocialMenuSlice,
} from 'generated/types/escher-videobook.types'
import { DateTime } from 'luxon'
import * as PrismicDOM from '@prismicio/helpers'
import {
  beautifySubchapterTitle,
  camelify,
  cutSubcontentType,
} from 'utils/string'
import { IWithUrl } from './contentStore/cmsAdapters/prismic/prismicSubcontentDataProcessor'
import * as contentType from './contentStore/contentTypes'
import { IUIElements } from './contentStore/contentTypes'
import { ILetter } from './contentStore/materials'
import { createSectionItem } from './contentStore/mobxHelpers'
import { SectionItem } from './contentStore/sectionItems/SectionItem'
import {
  IImageData,
  IRawSubcontentData,
} from './contentStore/subcontent_modules/subcontentInterfaces'

export interface IPrismicParagraph {
  paragraph: RichTextField
}

export interface ISubcontentPrismicData {
  /* eslint-disable */
  slice_type: string
  /* eslint-enable */
}

export interface IPrismicImageSlice {
  items: Array<{
    /* eslint-disable */
    image: IWithUrl
    image_description: RichTextField
    image_title: TitleField
    /* eslint-enable */
  }>
}

export interface IPrismicLegal {
  /* eslint-disable */
  primary: {
    section_title: TitleField
  }
  items: Array<{
    section_title?: TitleField
    section_running_text?: RichTextField
    year_for_logos?: number
  }>
  /* eslint-enable */
}

export interface IPrismicGlossary {
  /* eslint-disable */
  primary: {
    letter_of_alphabet: string
  }
  items: Array<{
    glossar_item?: TitleField
    glossar_item_multiline?: RichTextField
    glossar_item_text?: RichTextField
  }>
  /* eslint-enable */
}

export interface IPrismicBackIssues {
  /* eslint-disable */
  primary: {
    teaser_title_section: TitleField
    initial_display_teaser: number
    teaser_text_title: TitleField
    teaser_text_description: RichTextField
    back_issues_title: TitleField
    back_issues_subtitle: RichTextField
    initial_display_all: number
    back_to_hub_text: string
    download_button_text: string
    alternative_download_button_text: string
    order_on_external_button_text: string
    load_more_button_text: string
    teaser_more_button_text: string
  }
  items: Array<{
    cover: IWithUrl
    title: TitleField
    subtitle: TitleField
    description: RichTextField
    order_per_email: boolean
    order_on_external: boolean
    order_on_external_link: IWithUrl
    pdf: IWithUrl
    mailto_address: string
    mailto_subject: string
    mailto_body: RichTextField
  }>
  /* eslint-enable */
}

export interface IPrismicSupportersLogos {
  /* eslint-disable */
  year_for_logos?: string
  logo?: IWithUrl
  link_for_logo?: IWithUrl

  /* eslint-enable */
}

export interface IPrismicCookiesBannerData {
  /* eslint-disable */
  primary: {
    message?: RichTextField
    accept_button_text?: RichTextField
    more_information_text?: RichTextField
  }
  /* eslint-enable */
}
export interface IPrismicMetaTabs {
  /* eslint-disable */
  items: Array<{
    tab_name?: RichTextField
    displayed_title?: RichTextField
  }>
  /* eslint-enable */
}

export interface IPrismicMenuItems {
  /* eslint-disable */
  primary: {
    button_text_start_chapter_short?: string
    button_text_hide_subchapters?: string
    subchapter_summary_title?: string
    button_text_chapter_overview?: string
    button_text_link_to_sequence?: string
    button_text_start_chapter?: string
    placeholder_image?: IWithUrl
    close_menu_text?: string
    back_to_landing_text?: string
    knowledge_base_link_title?: string
    knowledge_base_link_desc?: RichTextField
    documentary_link_title?: string
    documentary_link_description?: RichTextField
    live_events_button?: string
    live_events_title?: string
    live_events_desc?: RichTextField
    live_events_url?: IWithUrl
  }
  items: Array<{
    legal_display?: string
    legal_link?: IWithUrl
  }>
  /* eslint-enable */
}
export interface IPrismicVideoBanner {
  /* eslint-disable */
  primary: {
    text?: RichTextField
    check_out_button_text?: RichTextField
    thumbnail?: IWithUrl
    background?: IWithUrl
  }
  /* eslint-enable */
}

export interface IPrismicChapter {
  /* eslint-disable */
  id: string
  uid?: string
  alternate_languages: Array<{
    lang: string
    uid?: string
  }>
  data: {
    chapter_number?: number
    chapter_title?: TitleField
    chapter_color?: string
    chapter_color_2?: string
    title_color?: string
    category?: string
    alternative_text_to_category?: string
    author?: RichTextField
    lead?: string
    next_chapter_preview_text?: string
    abstract?: string
    seo_or_social_media_description_of_the_chapter?: string
    subtitle?: string
    thumbnail?: IWithUrl
    background_image?: IWithUrl
    world_image?: IWithUrl
    world_marker?: IWithUrl
    lottie_chapter_title_animation?: IWithUrl
    intro_text?: string
    next_chapter_button_text?: string
    background?: { id: string }
    intro_video_mobile?: IWithUrl
    extended_video_desktop?: IWithUrl
    extended_video_mobile?: IWithUrl
    intro_video_desktop?: IWithUrl
    subchapters?: Array<{ [id: string]: contentType.ISubchapter }>
    next_chapter_background?: IWithUrl
    is_featured_with_background_image?: boolean
    is_featured_with_gray_background?: boolean
    ranking: string
    is_featured_on_top_banner?: boolean
    date_of_publication?: DateTime
    hub_teaser_text?: RichTextField
    related_articles?: Array<{
      related_article: {
        id: string
      }
    }>
    tags?: Array<{ tag: string }>
    no_cover?: boolean
    is_scrollytelling_chapter?: boolean
    icon_before_headline?: IWithUrl
    duration_of_audio?: number
    password?: string
  }

  /* eslint-enable */
}

/* eslint-disable */
export interface IPrismiscAussenraumSection {
  text: string
  title: string
  image: IWithUrl
  link: IWithUrl
  button_text: string
}
/* eslint-enable */

export interface IAussenraumSection {
  text: string
  title: string
  image: string
  link: string
}

export interface LegalMenu {
  id: string
  items: Array<{
    name: string
    link: {
      url: string
    }
  }>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  slice_label: string | null
  // eslint-disable-next-line @typescript-eslint/naming-convention
  slice_type: string
}

export const parseSubcontent = async (
  subcontentData: ISubcontentPrismicData[]
) => {
  const subcontentArray: SectionItem[] = []
  for (const subcontentItem of subcontentData) {
    const data = {
      type: camelify(subcontentItem.slice_type),
      data: subcontentItem,
    }
    const subcontent: SectionItem | null = await createSectionItem(data)
    if (subcontent) {
      subcontentArray.push(subcontent)
    }
  }
  return subcontentArray
}

export const parseImages = (imageSlices: IPrismicImageSlice[] = []) => {
  const images: IImageData[] = []
  let count = 0
  for (const imageSlice of imageSlices) {
    for (const imageData of imageSlice.items) {
      images.push({
        url: imageData.image?.url ?? '',
        description: PrismicDOM.asText(imageData.image_description) ?? '',
        title: PrismicDOM.asText(imageData.image_title) ?? '',
      })
      count++
    }
  }
  return {
    images,
    count,
  }
}

export const parseTextParagraphs = (
  paragraphsData: IPrismicParagraph[] = []
) => {
  console.dir(`parsing: ${paragraphsData}`)
  const paragraphs: string[] = []
  const regex = /(<a\s+href="[^"]*")(>)/gim
  for (const paragraphData of paragraphsData) {
    const str = PrismicDOM.asHTML(
      paragraphData.paragraph,
      (linkField) => linkField.id
    )
    const blankAnchors = str.replace(
      regex,
      (match, start: string, end: string) => start + ' target="_blank"' + end
    )
    paragraphs.push(blankAnchors)
  }
  return paragraphs
}

export const parseLegal = (inputData: IPrismicLegal[]) => {
  const result: contentType.LegalSection[] = []
  for (const item of inputData) {
    if (!item.primary.section_title) {
      continue
    }

    const title = PrismicDOM.asText(item.primary.section_title) ?? ''
    const entries: contentType.LegalSectionEntry[] = []
    for (const kapitel of item.items) {
      entries.push({
        title: PrismicDOM.asText(kapitel.section_title) ?? '',
        entry:
          PrismicDOM.asHTML(kapitel.section_running_text)?.replace(
            '&lt;br/&gt;',
            '<br/>'
          ) ?? '',
        year: kapitel.year_for_logos?.toString() ?? '',
      })
    }
    result.push({
      title,
      entries,
    })
  }
  return result
}

export const parseBackIssues = (inputData: IPrismicBackIssues[]) => {
  if (!inputData || !inputData.length) return []
  const result: contentType.BackIssuesSection[] = []
  for (const item of inputData) {
    if (!item.primary) return null
    const teaserTitleSection = item.primary.teaser_title_section
      ? PrismicDOM.asText(item.primary.teaser_title_section)
      : ''
    const initialDisplayTeaser = item.primary.initial_display_teaser ?? 3
    const teaserTextTitle = item.primary.teaser_text_title
      ? PrismicDOM.asText(item.primary.teaser_text_title)
      : ''
    const teaserTextDescription = item.primary.teaser_text_description
      ? PrismicDOM.asHTML(item.primary.teaser_text_description)
      : ''
    const backIssuesTitle = item.primary.back_issues_title
      ? PrismicDOM.asText(item.primary.back_issues_title)
      : ''
    const backIssuesSubtitle = item.primary.back_issues_subtitle
      ? PrismicDOM.asText(item.primary.back_issues_subtitle)
      : ''
    const initialDisplayAll = item.primary.initial_display_all ?? 6
    const issuesLoaded = initialDisplayAll
    const backToHubText = item.primary.back_to_hub_text ?? ''
    const downloadButtonText = item.primary.download_button_text ?? ''
    const alternativeDownloadButtonText =
      item.primary.alternative_download_button_text ?? ''
    const orderOnExternalButtonText =
      item.primary.order_on_external_button_text ?? ''
    const loadMoreButtonText = item.primary.load_more_button_text ?? ''
    const teaserMoreButtonText = item.primary.teaser_more_button_text ?? ''
    const backIssueItems: contentType.BackIssue[] = []
    for (const backIssue of item.items) {
      backIssueItems.push({
        title: backIssue.title ? PrismicDOM.asText(backIssue.title) : '',
        subtitle: backIssue.subtitle
          ? PrismicDOM.asText(backIssue.subtitle)
          : '',
        description: backIssue.description
          ? PrismicDOM.asHTML(backIssue.description).replace(
              '&lt;br/&gt;',
              '<br/>'
            )
          : '',
        orderPerEmail: backIssue.order_per_email,
        orderOnExternal: backIssue.order_on_external,
        orderOnExternalLink: backIssue.order_on_external_link?.url ?? '',
        cover: backIssue.cover?.url ?? '',
        pdf: backIssue.pdf?.url ?? '',
        mailToAddress: backIssue.mailto_address ?? '',
        mailToSubject: backIssue.mailto_subject ?? '',
        mailToBody: backIssue.mailto_body
          ? PrismicDOM.asHTML(backIssue.mailto_body)
              ?.replace(/<\/p>/gm, '\n')
              ?.replace(/(<([^>]+)>)/gim, '') ?? ''
          : '',
      })
    }
    result.push({
      teaserTitleSection,
      initialDisplayTeaser,
      teaserTextTitle,
      teaserTextDescription,
      backIssuesTitle,
      backIssuesSubtitle,
      initialDisplayAll,
      issuesLoaded,
      backToHubText,
      downloadButtonText,
      alternativeDownloadButtonText,
      orderOnExternalButtonText,
      loadMoreButtonText,
      teaserMoreButtonText,
      backIssueItems,
    })
  }
  return result
}

export const parseGlossaryLetters = (data: IPrismicGlossary[]) => {
  const result: ILetter[] = []
  for (const item of data) {
    const letter = item.primary.letter_of_alphabet?.toLowerCase() ?? ''
    const items = []
    for (const word of item.items) {
      items.push({
        title: PrismicDOM.asText(word.glossar_item) ?? '',
        titleMultiline: PrismicDOM.asHTML(word.glossar_item_multiline) ?? '',
        text: PrismicDOM.asText(word.glossar_item_text) ?? '',
      })
    }
    result.push({
      letter,
      items,
    })
  }
  return result
}

export const parseSupportersLogos = (inputData: IPrismicSupportersLogos[]) => {
  if (!inputData) {
    return
  }

  const result: contentType.SupportersLogos[] = []
  const resultsMap: { [year: string]: contentType.SupporterLogo[] } = {}
  for (const logoData of inputData) {
    const year = logoData.year_for_logos ?? ''
    if (!resultsMap[year]) {
      resultsMap[year] = []
    }
    resultsMap[year].push({
      image: logoData.logo?.url ?? '',
      link: logoData.link_for_logo?.url ?? '',
    })
  }
  for (const year in resultsMap) {
    result.push({
      year,
      logos: resultsMap[year],
    })
  }
  return result
}

export const parseCookiesBanner = (inputData: IPrismicCookiesBannerData) => {
  if (!inputData) return

  const result: contentType.CookiesBanner = {
    message:
      ((inputData.primary.message &&
        PrismicDOM.asHTML(inputData.primary.message)) as string) ?? '',
    acceptButtonText:
      ((inputData.primary.accept_button_text &&
        PrismicDOM.asText(inputData.primary.accept_button_text)) as string) ??
      '',
    moreInfoText:
      ((inputData.primary.more_information_text &&
        PrismicDOM.asText(
          inputData.primary.more_information_text
        )) as string) ?? '',
  }
  return result
}

export const parseMetaTabs = (inputData: IPrismicMetaTabs) => {
  if (!inputData ?? !inputData.items) return

  const result: contentType.MetaTab[] = []
  for (const item of inputData.items) {
    result.push({
      name:
        ((item.tab_name && PrismicDOM.asText(item.tab_name)) as string) ?? '',
      displayedTitle:
        ((item.displayed_title &&
          PrismicDOM.asText(item.displayed_title)) as string) ?? '',
    })
  }
  return result
}

export const parseVideoBanner = (inputData: IPrismicVideoBanner) => {
  if (!inputData) return

  const result: contentType.VideoBanner = {
    text:
      ((inputData.primary.text &&
        PrismicDOM.asHTML(inputData.primary.text)) as string) ?? '',
    buttonText:
      ((inputData.primary.check_out_button_text &&
        PrismicDOM.asText(
          inputData.primary.check_out_button_text
        )) as string) ?? '',
    banner: inputData.primary.thumbnail?.url ?? '',
    background: inputData.primary.background?.url ?? '',
  }
  return result
}

export const parseMenuItems = (inputData: IPrismicMenuItems) => {
  if (!inputData ?? !inputData.items) return
  const resultLegalEntries: contentType.LegalItemsInMenu[] = []
  for (const item of inputData.items) {
    resultLegalEntries.push({
      legalDisplay: item.legal_display ?? '',
      legalLink: item.legal_link?.url ?? '',
    })
  }

  const result: contentType.MenuItems = {
    buttonTextStartChapterShort:
      inputData.primary.button_text_start_chapter_short ?? '',
    subchapterSummaryTitle: inputData.primary.subchapter_summary_title ?? '',
    buttonTextHideSubchapters:
      inputData.primary.button_text_hide_subchapters ?? '',

    buttonTextStartChapter: inputData.primary.button_text_start_chapter ?? '',
    buttonTextChapterOverview:
      inputData.primary.button_text_chapter_overview ?? '',
    buttonTextLinkToSequence:
      inputData.primary.button_text_link_to_sequence ?? '',
    placeholderImage: inputData?.primary?.placeholder_image?.url ?? '',
    closeMenuText: inputData.primary.close_menu_text ?? '',
    backToLandingText: inputData.primary.back_to_landing_text ?? '',
    knowledgeBaseLinkTitle: inputData.primary.knowledge_base_link_title ?? '',
    documentaryLinkTitle: inputData.primary.documentary_link_title ?? '',
    knowledgeBaseLinkDesc:
      ((inputData.primary.knowledge_base_link_desc &&
        PrismicDOM.asHTML(
          inputData.primary.knowledge_base_link_desc
        )) as string) ?? '',
    documentaryLinkDesc:
      ((inputData.primary.documentary_link_description &&
        PrismicDOM.asHTML(
          inputData.primary.documentary_link_description
        )) as string) ?? '',
    liveEventsButton: inputData.primary.live_events_button ?? '',
    liveEventsMenuButtonTitle: inputData.primary.live_events_title ?? '',
    liveEventsMenuButtonDesc:
      ((inputData.primary.live_events_desc &&
        PrismicDOM.asHTML(inputData.primary.live_events_desc)) as string) ?? '',
    liveEventsLinkTo: inputData.primary.live_events_url?.url ?? '',
    legalEntries: resultLegalEntries,
  }

  return result
}

// eslint-disable-next-line
export const parseThumbnail = (data: any) => {
  const result: contentType.Thumbnail = {
    url: '',
    alt: '',
    copyright: '',
    responsiveUrls: {},
    dimensions: {
      width: 0,
      height: 0,
    },
  }
  const defaultFields = ['dimensions', 'alt', 'copyright', 'url']
  for (const resolution in data) {
    if (
      defaultFields.indexOf(resolution) < 0 &&
      typeof data[resolution] === 'object'
    ) {
      result.responsiveUrls[resolution] = {
        dimensions: data[resolution].dimensions,
        url: data[resolution].url,
      }
    }
  }
  result.url = data.url ?? ''
  result.alt = data.alt
  result.copyright = data.copyright

  if (result.dimensions) {
    Object.assign(result.dimensions, data.dimensions)
  }
  return result
}

export const parseChapter = (
  inputData: PrismicDocument
): contentType.IChapter => {
  const data = inputData?.data
  const result: contentType.IChapter = {
    id: inputData.id ?? '',
    uid: inputData.uid ?? '',
    type: 'chapter',
    idx: !(data.chapter_number ?? data.chapter_number === 0)
      ? 0
      : data.chapter_number > 0
      ? data.chapter_number
      : -1,
    localizations:
      inputData.alternate_languages.map((item) => {
        return {
          language: item.lang,
          uid: item.uid,
        }
      }) ?? [],
    title:
      ((data.chapter_title &&
        PrismicDOM.asText(data.chapter_title)) as string) ?? '',
    chapterName:
      ((data.chapter_name && PrismicDOM.asText(data.chapter_name)) as string) ??
      '',
    color: data.chapter_color ?? '',
    color2: data.chapter_color_2 ?? '',
    titleColor: data.title_color ?? '',
    category: data.category ?? '',
    categoryAlternativeText: data.alternative_text_to_category ?? '',
    author: ((data.author && PrismicDOM.asHTML(data.author)) as string) ?? '',
    leadText: ((data.lead && PrismicDOM.asHTML(data.lead)) as string) ?? '',
    outroText:
      ((data.next_chapter_preview_text &&
        PrismicDOM.asHTML(data.next_chapter_preview_text)) as string) ?? '',
    abstract:
      ((data.abstract && PrismicDOM.asHTML(data.abstract)) as string) ?? '',
    keyText: data.seo_or_social_media_description_of_the_chapter ?? '',
    subTitle: data.subtitle ?? '',
    thumbnail: (data.thumbnail && parseThumbnail(data.thumbnail)) ?? null,

    backgroundImage: data.background_image?.url ?? '',
    worldImage: data.world_image?.url ?? '',
    worldMarker: data.world_marker?.url ?? '',
    lottieChapterTitleAnimation: data.lottie_chapter_title_animation?.url ?? '',
    introText:
      ((data.intro_text && PrismicDOM.asText(data.intro_text)) as string) ?? '',
    introButtonText:
      ((data.intro_button_text &&
        PrismicDOM.asText(data.intro_button_text)) as string) ?? '',
    nextChapterButtonText: data.next_chapter_button_text ?? '',
    background: data.background?.id ?? '',
    introVideoMobile: data.intro_video_mobile?.url ?? '',
    introVideoDesktop: data.intro_video_desktop?.url ?? '',
    extendedVideoDesktop: data.extended_video_desktop?.url ?? '',
    extendedVideoMobile: data.extended_video_mobile?.url ?? '',
    subchaptersIds: data.subchapters
      ? data.subchapters.map(
          (subchapterData: { [id: string]: contentType.ISubchapter }) =>
            subchapterData.subchapter.id
        )
      : [],
    nextChapterBackground: data.next_chapter_background?.url ?? '',
    isFeaturedWithBackgroundImage:
      data.is_featured_with_background_image ?? false,
    isFeaturedWithGrayBackground:
      data.is_featured_with_gray_background ?? false,
    // Ranking is 1-3, so to show missing ranking use -1
    ranking: parseInt(data.ranking) ?? -1,
    isFeaturedOnTopBanner: data.is_featured_on_top_banner ?? false,
    publicationDate: data.date_of_publication ?? DateTime.local(),
    hubTeaserText:
      ((data.hub_teaser_text &&
        PrismicDOM.asHTML(data.hub_teaser_text)) as string) ?? '',
    relatedArticles: data.related_articles
      ? data.related_articles.map(
          // eslint-disable-next-line @typescript-eslint/naming-convention
          (articleData: { related_article: { id: string } }) =>
            articleData.related_article.id
        )
      : [],
    tags: data.tags
      ? data.tags.map((tagData: { tag: string }) => tagData.tag)
      : [],
    noCover: data.no_cover ?? false,
    isScrollytellingChapter: data.is_scrollytelling_chapter ?? false,
    password: data.password ?? '',
    iconBeforeHeadline: data.icon_before_headline?.url ?? '',
    durationOfPodcast: data.duration_of_audio,
  }
  return result
}

export const createSubchapter = (
  subchapterData: PrismicDocument,
  sectionItems: SectionItem[]
): contentType.ISubchapter => {
  const data = subchapterData.data
  return {
    id: subchapterData.id,
    uid: subchapterData.uid ?? '',
    type: 'subchapter',
    backgroundId: data.bg_main?.id || '',
    fallbackBackgroundId: data.link_to_subchapter_fallback_background?.id || '',
    thumbnail: data.thumbnail && parseThumbnail(data.thumbnail),
    title:
      (
        data.subchapter_title &&
        beautifySubchapterTitle(PrismicDOM.asText(data.subchapter_title))
      ).replace('<br/>', '<br/>') || '',
    titleColor: data.title_color || '',
    styledPrefix: data.prefix_vertical_subchapter_title || '',
    sidebarText: PrismicDOM.asText(data.sidebar_text) ?? '',
    sectionItems,
    textPositionLeft: !!data.text_position_left,
    backgroundOpenable: !!data.background_openable,
  }
}

export const parseFullScreenSubcontent = (subcontent: PrismicDocument) => {
  return {
    id: subcontent.id || '',
    type: subcontent.type && cutSubcontentType(subcontent.type),
    data: subcontent as IRawSubcontentData['data'],
  }
}

export const parseImpressum = (document: PrismicDocument) => {
  const data = document.data
  return {
    id: document.id || '',
    title: PrismicDOM.asText(data.title) ?? '',
    sections: parseLegal(data.body),
    supportersLogos: parseSupportersLogos(data.logos_supporters) ?? [],
  }
}

export const parseQuellen = (document: PrismicDocument) => {
  const data = document.data
  return {
    id: document.id || '',
    title: PrismicDOM.asText(data.title) ?? '',
    sections: parseLegal(data.body),
  }
}

export const parseGlossary = (result: PrismicDocument) => {
  return {
    id: result.id || '',
    uid: result.uid ?? '',
    letters: parseGlossaryLetters(result.data.body) || [],
  }
}

export const initIntroPage = (lang: string) => {
  return {
    lang,
    title: '',
    secondaryTitle: '',
    richTitle: '',
    backgroundImage: '',
    startButtonText: '',
    languageSelectionPhrase: '',
    soundSwitchOn: '',
    soundSwitchOff: '',
    titlePoster: '',
    introVideo: '',
    introAnimation: {
      desktop: '',
      tablet: '',
      mobile: '',
    },
    introductionMovieTitle: '',
    skipVideoButtonText: '',
    backgroundVideoLoopDesktop: '',
    backgroundVideoLoopMobile: '',
    promotionBar: {
      promoBarText: '',
      promoButtonText: '',
      promoButtonLink: '',
      promoBarBackgroundColor: '',
    },
    awards: [
      {
        logo: '',
        logoLink: '',
      },
    ],
  }
}

// TODO-Interface: Create interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseIntro = (data: any) => {
  return {
    title: (data.title && PrismicDOM.asText(data.title)) || '',
    richTitle: (data.rich_title && PrismicDOM.asHTML(data.rich_title)) || '',
    secondaryTitle:
      (data.secondary_title && PrismicDOM.asText(data.secondary_title)) || '',
    backgroundImage: data.background_image?.url || '',
    startButtonText:
      (data.start_button_text && PrismicDOM.asText(data.start_button_text)) ||
      '',
    introductionMovieTitle: data.introduction_movie_title || '',
    skipVideoButtonText: data.skip_intro_movie_button_text || '',
    languageSelectionPhrase:
      (data.language_selection && PrismicDOM.asText(data.language_selection)) ||
      '',
    soundSwitchOn:
      (data.sound_switch_on && PrismicDOM.asHTML(data.sound_switch_on)) || '',
    soundSwitchOff:
      (data.sound_switch_off && PrismicDOM.asHTML(data.sound_switch_off)) || '',
    titlePoster: data.title_poster?.url || '',
    introVideo: data['intro-video']?.url || '',
    introAnimation:
      {
        desktop: data.intro_animation_desktop?.url,
        tablet: data.intro_animation_tablet?.url,
        mobile: data.intro_animation_mobile?.url,
      } || {},
    backgroundVideoLoopDesktop: data.background_video_loop_desktop?.url || '',
    backgroundVideoLoopMobile: data.background_video_loop_mobile?.url || '',
    promotionBar: {
      promoBarText:
        (data.promo_bar_text && PrismicDOM.asHTML(data.promo_bar_text)) || '',
      promoButtonText: data.promo_button_text || '',
      promoButtonLink: data.promo_button_link?.url || '',
      promoBarBackgroundColor: data.promo_bar_background_color,
    },
    awards: data.awards
      ? // TODO-Interface: Create interface
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data.awards.map((awardData: any) => {
          return {
            logo: awardData?.logo?.url,
            logoLink: awardData?.logo_link?.url,
          }
        })
      : [],
  }
}

export const createPartialChapter = (
  chapter: IPrismicChapter
): contentType.PartialChapter => {
  const data = chapter.data
  return {
    id: chapter?.id || '',
    uid: chapter?.uid ?? '',
    type: 'chapter' as contentType.ContentTypes,
    idx: !(data.chapter_number ?? data.chapter_number === 0)
      ? 0
      : data.chapter_number > 0
      ? data.chapter_number
      : -1,
    title: (data.chapter_title && PrismicDOM.asText(data.chapter_title)) ?? '',
    category: data.category ?? '',
    categoryAlternativeText: data.alternative_text_to_category ?? '',
    author: (data.author && PrismicDOM.asHTML(data.author)) ?? '',
    thumbnail: (data.thumbnail && parseThumbnail(data.thumbnail)) ?? null,
    lottieChapterTitleAnimation: data.lottie_chapter_title_animation?.url ?? '',
    // Ranking is 1-3, so to show missing ranking use -1
    ranking: parseInt(data.ranking) || -1,
    publicationDate: data.date_of_publication ?? DateTime.local(),
    hubTeaserText:
      (data.hub_teaser_text && PrismicDOM.asHTML(data.hub_teaser_text)) ?? '',
    tags: data.tags
      ? data.tags.map((tagData: { tag: string }) => tagData.tag)
      : [],
    subchaptersIds: data.subchapters
      ? data.subchapters.map(
          (subchapterData: { [id: string]: contentType.ISubchapter }) =>
            subchapterData.subchapter.id
        )
      : [],
  }
}

export const parseCommonUIElements = (
  // TODO-Interface: Create interface
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  results: any
): contentType.IUIElements => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const UIElements: contentType.IUIElements = {} as any

  // Carl Lutz, Nuclear Games and SNB use a prismic field "scroll_indicator_text" in ui_elements but for nuclear games we need it as rich text. This is to correctly build the variable.
  const scrollIndicatorText = results[0].data.scroll_indicator_text ?? ''
  UIElements.idleTimePopupText = results[0].data.idle_time_popup_text ?? ''
  UIElements.blockedAudioAutoplayTextInfo =
    (results[0].data.blocked_audio_autoplay_text_info &&
      PrismicDOM.asHTML(results[0].data.blocked_audio_autoplay_text_info)) ||
    ''
  UIElements.scrollToIntroText =
    typeof scrollIndicatorText === 'object'
      ? PrismicDOM.asHTML(scrollIndicatorText)
      : scrollIndicatorText
  UIElements.soundIconOn = results[0].data.sound_icon_on?.url || ''
  UIElements.soundIconOff = results[0].data.sound_icon_off?.url || ''
  UIElements.soundOnPhrase = results[0].data.sound_on_phrase || ''
  UIElements.soundOffPhrase = results[0].data.sound_off_phrase || ''
  UIElements.SEODescription = results[0].data.seo_app_description || ''
  UIElements.SEOImage = results[0].data.seo_app_image?.url || ''
  UIElements.documentaryURL = results[0].data.documentary_url?.url || ''
  UIElements.SEOTitle =
    (results[0].data.seo_app_title &&
      PrismicDOM.asText(results[0].data.seo_app_title)) ||
    ''
  UIElements.SEOSubtitle =
    (results[0].data.seo_app_subtitle &&
      PrismicDOM.asHTML(results[0].data.seo_app_subtitle)) ||
    ''
  UIElements.SEOKeywords =
    results[0].data.seo_app_keywords?.map(
      (item: { keyword: string }) => item.keyword
    ) || []

  UIElements.passwordSubmitButtonText =
    results[0].data.password_submit_button_text
  UIElements.passwordWrong = results[0].data.password_wrong

  UIElements.chapterDateFormat =
    (results[0].data.chapter_date_format &&
      PrismicDOM.asText(results[0].data.chapter_date_format)) ||
    ''
  UIElements.loadingSpinner = results[0].data.loading_spinner?.url || ''
  UIElements.dossiers = []

  return UIElements
}

// TODO-Interface: Create interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseUIElementStaticUIText = (item: any) => {
  const {
    all_stories: allStories,
    recent,
    tagged_by: taggedBy,
    read_now: readNow,
    more_articles: moreArticles,
    all_articles: allArticles,
    discover_our_categories: discoverOurCategories,
    read_abstract: readAbstract,
    contents,
    chapter: chapterText,
    related_articles_info: relatedArticlesInfo,
    rotate_device: rotateDeviceText,
    ie_previous_chapter_info_text: IEPreviousChapterButtonText,
    ie_next_chapter_info_text: IENextChapterButtonText,
  } = item.primary

  return (
    {
      allStories,
      recent,
      taggedBy,
      readNow,
      moreArticles,
      allArticles,
      discoverOurCategories,
      readAbstract,
      contents,
      chapterText,
      relatedArticlesInfo,
      rotateDeviceText,
      IEPreviousChapterButtonText,
      IENextChapterButtonText,
      categories: item.items.map((c: contentType.IUICategoryPrismicData) => {
        return {
          name: c?.category_name[0]?.text,
          filter: c?.filter_name,
          longName: c?.category_long_name[0]?.text,
          description: c?.category_description[0]?.text,
          img: parseThumbnail(c?.image_category_thumbnail),
          goToText: c?.category_go_to_text,
        }
      }),
    } || {}
  )
}

// TODO-Interface: Create interface
// TODO-DANIOTH: This is now used only in Danioth
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseUIElementLegalMenu = (
  menuItems: unknown,
  uiElements: IUIElements
) => {
  const items = menuItems as LegalMenu
  uiElements.legalMenu = []
  if (items.items.length > 0) {
    for (const item of items.items) {
      uiElements.legalMenu.push({
        name: item.name ?? '',
        link: item.link.url ?? '',
      })
    }
  }
}

export const parseUIElementLegalMenuDirect = (legalItems: unknown) => {
  const items = legalItems as UiElementsDocumentDataBodyLegalMenuSlice
  const legalMenu = []

  if (items.items.length > 0) {
    for (const item of items.items) {
      legalMenu.push({
        name: item.name ?? '',
        link: item.link ?? '',
      })
    }
  }

  return legalMenu
}

export const parseUIElementLegalSocialMenu = (socialItems: unknown) => {
  const items = socialItems as UiElementsDocumentDataBodySocialMenuSlice
  const socialMenu = []

  if (items.items.length > 0) {
    for (const item of items.items) {
      socialMenu.push({
        name: item.name ?? '',
        link: (item.link as FilledLinkToWebField).url ?? '',
        image: item.social_icon.url ?? '',
      })
    }
  }

  return socialMenu
}

// TODO-Interface: Create interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseUIElementLoadingScreen = (item: any) => {
  const {
    loading_spinner: loadingSpinner,
    background_image: backgroundImage,
    infotext_fallback_version: infotextFallbackVersion,
    fallback_no_button_text: fallbackNoButtonText,
    fallback_yes_button_text: fallbackYesButtonText,
    info_text_after_rotating_device: infoTextAfterRotatingDevice,
  } = item.primary

  return (
    {
      loadingSpinner: loadingSpinner.url,
      backgroundImage: backgroundImage.url,
      infotextFallbackVersion: PrismicDOM.asHTML(infotextFallbackVersion) ?? '',
      fallbackNoButtonText: fallbackNoButtonText ?? '',
      fallbackYesButtonText: fallbackYesButtonText ?? '',
      infoTextAfterRotatingDevice: infoTextAfterRotatingDevice ?? '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      waitingTexts: item.items.map((w: { waiting_text: RichTextField }) => ({
        text: PrismicDOM.asHTML(w?.waiting_text),
      })),
    } || {}
  )
}

// TODO-Interface: Create interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseUIElementMarketingPopup = (item: any) => {
  const data = item.primary

  return (
    {
      title:
        (data.newsletter_sign_up_title &&
          PrismicDOM.asText(data.newsletter_sign_up_title)) ||
        '',
      description:
        (data.newsletter_sign_up_description &&
          PrismicDOM.asHTML(data.newsletter_sign_up_description)) ||
        '',
      inputPlaceholder: data.newsletter_sign_up_input_placeholder || '',
      buttonText: data.newsletter_sign_up_button || '',
      checkboxLabel:
        (data.newsletter_sign_up_checkbox_label &&
          PrismicDOM.asHTML(data.newsletter_sign_up_checkbox_label)) ||
        '',
      emailActionUrl: data.action_url_to_mailchimp?.url || '',
      idMailchimp: data.id_mailchimp || '',
      userMailchimp: data.user_mailchimp_no || '',
      emailMailchimp: data.name_attribute_mailchimp_for_email_field || '',
      background: data.background?.url || '',
    } || {}
  )
}

// TODO-Interface: Create interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseMoreInfoTexts = (item: any) => {
  const data = item.primary

  return {
    expandText: data.mehr,
    hideText: data.weniger,
  }
}

export const parseFetchedById = (
  result: IPrismicChapter | IRawSubcontentData
) => {
  return {
    id: result.id || '',
    type:
      (result as IRawSubcontentData).type &&
      cutSubcontentType((result as IRawSubcontentData).type),
    data: result,
  }
}

export const getLinkFieldUrl: (link?: LinkField) => string = (link) => {
  const isFilledLinkWithUrl = (
    link: LinkField
  ): link is
    | FilledLinkToWebField
    | FilledLinkToDocumentField
    | FilledLinkToMediaField => {
    return link.link_type !== LinkType.Any
  }

  if (link && isFilledLinkWithUrl(link)) {
    return link.url ?? ''
  }

  return ''
}

export const initKnowledgeBase = (lang: string) => {
  return {
    lang,
    buttonTextToMain: '',
    title: '',
    subtitle: '',
    description: '',
    logoTopLeft: '',
    logoDescriptionTopLeft: '',
    backgroundImage: '',
  }
}

// TODO-Interface: Create interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseKnowledgeBase = (data: any, lang: string) => {
  return {
    lang,
    buttonTextToMain: data.button_text_to_main ?? '',
    title: data.title ?? '',
    subtitle: data.subtitle ?? '',
    description:
      (data.description && PrismicDOM.asText(data.description)) ?? '',
    logoTopLeft: data.logo_top_left.url ?? '',
    logoDescriptionTopLeft: data.logo_description_top_left ?? '',
    backgroundImage: data.background_image.url ?? '',
  }
}
