import { importFromProject } from 'utils/importHelper'
import { prismicDataParser } from './prismicSubcontentDataProcessor'

interface IDataParser {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [id: string]: Function
}

export const prismicSubcontentDataAdapter: () => Promise<IDataParser> = async () => {
  let combinedProcessor = prismicDataParser
  const shouldThrowModuleLoadingError = false
  const projectProcessor = await importFromProject<Partial<IDataParser>>(
    'cmsAdapters/prismic/prismicSubcontentDataProcessor',
    shouldThrowModuleLoadingError
  )
  if (projectProcessor) {
    combinedProcessor = {
      ...prismicDataParser,
      ...projectProcessor,
    }
  }
  return combinedProcessor
}
